.AdminManageJobs {
    height: calc(80vh);
    width: auto;
    padding-top: 34px;
    padding-left: 32px;
    overflow-y: auto;
}

/* width */
.AdminManageJobs::-webkit-scrollbar {
    width: 6px;
}

/* Track */
.AdminManageJobs::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
.AdminManageJobs::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.4);
    border-radius: 15px;
}

/* Handle on hover */
.AdminManageJobs::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.new-jobs-container h1,
.ongoing-jobs-container h1,
.archived-job-container h1 {
    font-family: 'Lato', sans-serif;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    color: #2e353a;
    margin-bottom: 25px;
}

.new-jobs-container {
    margin-bottom: 45px;
}

.admin-job-card {
    border: 1px solid #d7def0;
    box-sizing: border-box;
    border-radius: 15px;
    padding: 25px 15px;
    cursor: pointer;
}
.admin-job-card h1 {
    font-family: 'DM Serif Display', sans-serif;
    font-weight: 400;
    font-size: 20px;
    line-height: 100%;
    color: rgba(46, 53, 58, 0.8);
    margin-bottom: 10px;
}
.admin-job-card h2 {
    font-family: 'Lato', sans-serif;
    font-weight: 400;
    font-size: 15px;
    line-height: 100%;
    color: rgba(46, 53, 58, 0.8);
    margin-bottom: 5px;
}
.admin-job-card p {
    text-align: end;
    font-family: 'Lato', sans-serif;
    font-weight: 400;
    font-size: 15px;
    line-height: 100%;
    color: rgba(38, 50, 56, 0.5);
}

/* Job Details */
.AdminJobDetails {
    height: calc(84vh);
    width: auto;
    overflow-y: auto;
    overflow-x: hidden;
}

/* width */
.AdminJobDetails::-webkit-scrollbar {
    width: 6px;
}

/* Track */
.AdminJobDetails::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
.AdminJobDetails::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.4);
    border-radius: 15px;
}

/* Handle on hover */
.AdminJobDetails::-webkit-scrollbar-thumb:hover {
    background: #555;
}
