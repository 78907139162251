.nav-profile {
    display: flex;
    justify-content: center;
    align-items: center;
}

.nav-avatar {
    height: 50px;
    width: 50px;
    border-radius: 50%;
    margin-right: 10px;
}

.nav-profile span i {
    color: rgba(21, 15, 55, 0.6);
    font-size: 15px;
    cursor: pointer;
    transition: all 400ms ease;
}
.nav-profile span i:hover {
    color: rgb(21, 15, 55);
}