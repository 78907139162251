.AdminPanel {
    width: 100vw;
    height: 100vh;
    display: flex;
}

/* Sidebar */
.Admin-sidebar {
    width: 15%;
    height: 100%;
    /* float: left; */
    border: 1px solid #d7def0;
    padding-top: 26px;
    padding-left: 32px;
    padding-right: 32px;
    overflow: auto;
}

.sidebar-logo {
    height: 35px;
    width: 100%;
    text-align: start;
    padding-left: 10px;
    margin-bottom: 68px;
}
.sidebar-logo img {
    height: 100%;
}

.Admin-sidebar button {
    display: block;
    width: 187px;
    height: 38px;
    background: none;
    border: none;
    outline: none;
    border-radius: 4px;
    font-family: 'Lato', sans-serif;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    color: #d7def0;
    cursor: pointer;
    text-align: start;
    padding: 12px;
    margin-bottom: 15px;
}
.Admin-sidebar button.btn-selected {
    background: #d7def0;
    color: #4544a2;
    transition: all 500ms ease;
}
.Admin-sidebar button i {
    padding-right: 20px;
    font-size: 16px;
}

.sidebar-menu-icon {
    display: none;
}
@media only screen and (max-width: 992px) {
    body {
        overflow-x: hidden;
    }
    .AdminPanel {
        flex-direction: column;
    }
    .Admin-main .content-nav {
        display: none;
    }

    .Admin-sidebar {
        width: 100%;
        height: fit-content;
        padding-bottom: 20px;
        padding-left: 20px;
    }
    .sidebar-logo {
        width: 90%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 0;
    }
    .sidebar-logo img {
        height: 35px;
    }
    .sidebar-menu-icon {
        display: block;
        cursor: pointer;
        color: #4544a2;
        padding-right: 20px;
    }
    .admin-sidebar-btns {
        display: none;
    }

    .Admin-main {
        width: 100% !important;
    }
}

/* Main */
.Admin-main {
    width: 80%;
    height: 100%;
    /* float: right; */
}

.content-nav {
    height: 50px;
    width: 100%;
    border-bottom: 1px solid #d7def0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 25px 0;
}

.content-nav button {
    margin-right: 38px;
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
}
.content-nav button i {
    font-size: 17px;
    color: rgba(46, 53, 58, 0.7);
}

.content-nav div {
    display: flex;
    align-items: center;
    padding-right: 50px;
    font-family: 'Lato', sans-serif;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: rgba(38, 50, 56, 0.5);
}
.content-nav div i {
    font-size: 30px;
    padding-right: 10px;
    color: #2e353a;
}
.content-nav div span p:first-child {
    color: #404040;
}

/* Notifications */
.notification {
    padding: 5px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.unread {
    height: 50px;
    width: 50px;
    border-radius: 50%;
    color: red;
    z-index: 50;
}

.open-notif {
    font-size: 13px;
    padding-top: 20px;
    color: #4544a2;
    text-decoration: underline;
}

.notif-modal h1 {
    font-family: 'Lato', sans-serif;
    font-size: 18px;
    font-weight: 800;
    color: #2e353a;
    margin-top: 30px;
}

.notif-modal p {
    font-family: 'Lato', sans-serif;
    font-size: 16px;
    font-weight: 500;
    margin: 15px 0;
}
.notif-modal p span {
    font-weight: 600;
}

.notif-modal button {
    cursor: pointer;
    border: none;
    outline: none;
    background-color: #4544a2;
    color: #fff;
    border-radius: 15px;
    padding: 5px 10px;
}
