.AdminProjects {
	width: auto;
	height: calc(80vh);
	overflow-y: auto;
	padding-top: 34px;
	padding-left: 32px;
}

/* width */
.AdminProjects::-webkit-scrollbar {
	width: 6px;
}

/* Track */
.AdminProjects::-webkit-scrollbar-track {
	background: #f1f1f1;
}

/* Handle */
.AdminProjects::-webkit-scrollbar-thumb {
	background: rgba(0, 0, 0, 0.4);
	border-radius: 15px;
}

/* Handle on hover */
.AdminProjects::-webkit-scrollbar-thumb:hover {
	background: #555;
}

.AdminProjects h1 {
	font-family: 'Lato', sans-serif;
	font-weight: 700;
	font-size: 20px;
	line-height: 24px;
	color: #2e353a;
	margin-bottom: 25px;
}
