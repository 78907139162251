.CandidateDashboardProfile {
    padding-top: 40px;
    padding-left: 50px;
    width: auto;
}

.CandidateDashboardProfile .header {
    font-family: 'Lato', sans-serif;
    font-weight: 700;
    font-size: 25px;
    line-height: 24px;
    color: #2E353A;
    text-align: center;
}

.profile-container {
    width: 100%;
    margin: 0 auto;
}

.profile-container .avatar-container {
    position: relative;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px dashed #d7def0;
    width: fit-content;
    margin: auto;
    margin-top: 20px;
    padding: 20px;
    border-radius: 15px;
}
.profile-container .avatar-container img {
    height: 60px;
    width: 60px;
    border-radius: 50%;
}
.profile-container .avatar-container span {
    display: flex;
    flex-direction: column;
    margin-left: 15px;
}
.profile-container .avatar-container span input {
    margin-bottom: 10px;
}
.profile-container .avatar-container span button {
    width: fit-content;
    cursor: pointer;
    padding: 10px 15px;
    background: #4544A2;
    border-radius: 10px;
    font-family: Lato;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 100%;
    color: #FFFFFF;
    border: none;
    outline: none;
}

.avatar-container h1 {
    font-family: 'Lato', sans-serif;
    font-weight: 600;
    font-size: 15px;
    color: #2E353A;
    margin-right: 20px;
}

.field-container {
    width: fit-content;
    margin: 30px auto;
    border: 1px solid #d7def0;
    border-radius: 15px;
    padding: 15px 30px;
    box-shadow: 1px 0px 37px 6px rgba(0,0,0,0.25);
    -webkit-box-shadow: 1px 0px 37px 6px rgba(0,0,0,0.25);
    -moz-box-shadow: 1px 0px 37px 6px rgba(0,0,0,0.25);
}

.field-container div h2 {
    font-family: 'Lato', sans-serif;
    font-weight: 500;
    font-size: 20px;
    line-height: 20px;
    margin-bottom: 5px;
    margin-top: 5px;
    color: #2E353A;
}

.input-container {
    display: flex;
}

.input-container span {
    margin: 10px;
}

.input-container span label {
    display: block;
    margin-bottom: 5px;
    font-family: 'Lato', sans-serif;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #4544A2;
}

.input-container span h3 {
    font-family: 'Lato', sans-serif;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 4px;
}

.field-container div input,
.field-container div select {
    display: block;
    background: rgba(233, 236, 242, 0.4);
    border: 1px solid #E9ECF2;
    box-sizing: border-box;
    border-radius: 12px;
    padding: 10px 15px;
}

.profile-select-input {
    background: rgba(233, 236, 242, 0.4);
    border: 1px solid #E9ECF2;
    box-sizing: border-box;
    border-radius: 12px;
    padding: 10px 15px;
    cursor: pointer;
}

.add-btn {
    background-color: #4544A2;
    color: #fff;
    font-family: 'Lato', sans-serif;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 4px;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 5px 10px;
    border-radius: 10px;
}

.save-btn {
    background-color: #25C077;
    color: #fff;
    font-family: 'Lato', sans-serif;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 4px;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 10px;
    border-radius: 10px;
}