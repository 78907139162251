.DashboardFooter {
    height: 100%;
    /* background-color: #252526; */
    display: block;
    /* text-align: center; */
    /* color: #fff; */
    /* padding: 20px;
	padding-left: 60px; */
    font-family: 'Roboto', sans-serif;
    margin-top: 20px;
}
.DashboardFooter h1 {
    margin-bottom: 40px;
    font-size: 32px;
    font-weight: 800;
    color: #263238;
    position: relative;
    width: fit-content;
    padding-left: 60px;
}
.DashboardFooter h1::after {
    content: '';
    width: 30%;
    height: 8px;
    border-radius: 10px;
    background-color: var(--primary);
    position: absolute;
    bottom: -10px;
    right: -20px;
}
.things-todo {
    width: 100%;
    padding: 20px;
}
.dashboard-footer-div {
    /* color: #fff; */
    width: 210px;
    margin: auto;
    border-radius: 15px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    transition: all 500ms ease;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
    padding-top: 20px;
}
.dashboard-footer-div:hover {
    transform: scale(1.1);
}
.dashboard-footer-div i {
    color: #4544a2;
    font-size: 28px;
    margin-bottom: 15px;
    height: 40px;
}
.dashboard-footer-div img {
    height: 30px;
    width: 30px;
    fill: #000;
    margin-bottom: 15px;
}
.dashboard-footer-div h2 {
    margin-bottom: 10px;
    font-size: 15px;
    height: 30px;
}
.dashboard-footer-div p {
    width: 95%;
    text-align: center;
    font-size: 14px;
    line-height: 25px;
    line-break: loose;
    color: #263238d9;
    height: 150px;
}

.footer-social-media-text {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    border-top: 2px solid #d7def0;
    padding: 15px 0;
    margin-top: 20px;
}
.footer-social-media-text a {
    margin: 0 15px;
}
.footer-social-media-text a i {
    color: #fff;
}
.whatsapp-btn {
    background-color: #25d366;
    padding: 6px 10px;
    border-radius: 6px;
}
.discord-btn {
    background-color: #5865f2;
    padding: 6px 8px;
    border-radius: 6px;
}

@media screen and (max-width: 768px) {
    .dashboard-footer-div {
        width: 250px;
        padding-bottom: 20px;
        padding-top: 20px;
    }
    .dashboard-footer-div p {
        height: fit-content;
    }
    .DashboardFooter h1 {
        font-size: 25px;
    }
}
