.AdminDashboard {
	width: 100%;
	height: calc(82vh);
	overflow-y: auto;
}

/* width */
.AdminDashboard::-webkit-scrollbar {
	width: 6px;
}

/* Track */
.AdminDashboard::-webkit-scrollbar-track {
	background: #f1f1f1;
}

/* Handle */
.AdminDashboard::-webkit-scrollbar-thumb {
	background: rgba(0, 0, 0, 0.4);
	border-radius: 15px;
}

/* Handle on hover */
.AdminDashboard::-webkit-scrollbar-thumb:hover {
	background: #555;
}

.admin-dash-content {
	padding-left: 40px;
	padding-top: 12px;
	padding-right: 55px;
}
.admin-dash-content h2 {
	font-family: 'Lato', sans-serif;
	font-weight: 700;
	font-size: 20px;
	line-height: 24px;
	color: #2e353a;
	margin-bottom: 20px;
}
.admin-dash-content h2 i {
	padding-left: 30px;
	font-size: 16px;
	cursor: pointer;
}

.weekly-stats {
	width: 100%;
	height: 116px;
	border: 1px solid #e6e7e9;
	box-sizing: border-box;
	border-radius: 15px;
	margin-bottom: 16px;
	display: flex;
	justify-content: space-evenly;
	padding-top: 20px;
}
.weekly-stats span {
	text-align: center;
}
.weekly-stats span h1 {
	font-family: 'Lato', sans-serif;
	font-weight: 400;
	font-size: 20px;
	line-height: 24px;
	color: rgba(46, 53, 58, 0.8);
	margin-bottom: 5px;
}
.weekly-stats span p {
	font-family: 'Lato', sans-serif;
	font-weight: 800;
	font-size: 40px;
	line-height: 48px;
	color: #6871e9;
}

.new-jobs {
	width: 70%;
	float: left;
}
.new-jobs span {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.new-jobs span h3 {
	font-family: 'Lato', sans-serif;
	font-weight: bold;
	font-size: 15px;
	line-height: 100%;
	color: #4544a2;
	cursor: pointer;
}

.new-jobs-card {
	border: 1px solid #e6e7e9;
	box-sizing: border-box;
	border-radius: 15px;
	padding-top: 26px;
	padding-left: 20px;
	padding-bottom: 14px;
	padding-right: 25px;
	color: #2e353a;
}
.new-jobs-card h3 {
	font-family: 'DM Serif Display', sans-serif;
	font-weight: 400;
	font-size: 20px;
	line-height: 100%;
	margin-bottom: 10px;
}
.new-jobs-card p {
	font-family: 'Lato', sans-serif;
	font-weight: 400;
	font-size: 15px;
	line-height: 100%;
}
.new-jobs-card p:last-child {
	text-align: end;
	color: rgba(38, 50, 56, 0.5);
}

.create-btns {
	width: 25%;
	float: right;
}
.create-btns div {
	width: 100%;
	border: 1px solid #e6e7e9;
	box-sizing: border-box;
	border-radius: 15px;
	padding: 30px 18px;
	margin-bottom: 15px;
	padding-bottom: 15px;
}
.create-btns div h1 {
	font-family: 'DM Serif Display', sans-serif;
	font-weight: 400;
	font-size: 25px;
	line-height: 100%;
	color: #2e353a;
	margin-bottom: 8px;
	text-align: center;
}
.create-btns div p {
	font-family: 'Lato', sans-serif;
	font-weight: 400;
	font-size: 14px;
	line-height: 100%;
	color: rgba(38, 50, 56, 0.5);
	margin-bottom: 25px;
	text-align: center;
}
.create-btns div button {
	background: #4544a2;
	color: #fff;
	border-radius: 8px;
	font-family: 'Lato', sans-serif;
	font-weight: 400;
	font-size: 12px;
	line-height: 100%;
	border: none;
	outline: none;
	cursor: pointer;
	padding: 10px 46px;
	margin-left: 35px;
}

.admin-dash-not-found {
	width: 100%;
	height: 100%;
	text-align: center;
	margin-top: 8rem;
	font-family: 'Lato', sans-serif;
	font-weight: 400;
	font-size: 14px;
	color: #2e353a;
}

@media only screen and (max-width: 768px) {
	.AdminDashboard {
		padding-bottom: 20px;
	}
	.dashboard-main {
		display: flex;
		flex-direction: column-reverse;
	}

	.weekly-stats {
		width: 100%;
		height: fit-content;
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		grid-template-rows: repeat(2, 1fr);
		grid-column-gap: 10px;
		grid-row-gap: 0px;
		border: none;
		padding-top: 0;
	}
	#events-attended {
		display: none;
	}
	.weekly-stats span {
		width: 100%;
		border: 1px solid #d7def0;
		box-sizing: border-box;
		border-radius: 10px;
		margin-right: 10px;
		margin-bottom: 10px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 10px;
		text-align: start;
	}

	.new-jobs {
		float: none;
		width: 100%;
	}
	.create-btns {
		float: none;
		width: 100%;
		margin-bottom: 16px;
	}
	.create-btns div {
		display: flex;
		flex-direction: column;
		/* justify-content: center; */
		align-items: center;
	}
	.create-btns div button {
		margin-left: 0;
		width: 100px;
		padding: 10px;
		font-size: 16px;
	}
}

/* Create Event Modal */
.event-modal-content {
	width: 100%;
}

.event-modal-content h1 {
	text-align: center;
	margin-bottom: 25px;
	font-family: 'DM Serif Display', sans-serif;
	font-weight: 400;
	font-size: 24px;
	line-height: 100%;
	color: #2e353a;
}

.banner-upload {
	width: fit-content;
	margin: 0 auto;
	margin-bottom: 20px;
	padding: 26px 90px;
	border: 2px dashed #d7def0;
	box-sizing: border-box;
	border-radius: 12px;
	font-family: 'Lato', sans-serif;
	font-weight: 500;
	text-align: center;
}
.banner-upload h2 {
	font-size: 17px;
	line-height: 20px;
	color: #2e353a;
	margin-bottom: 6px;
}
.banner-upload input {
	display: block;
	width: auto;
	margin: 15px auto;
	padding-left: 40px;
}
.banner-upload button {
	border: none;
	outline: none;
	background-color: #4544a2;
	color: #fff;
	border-radius: 15px;
	padding: 10px 15px;
	cursor: pointer;
}
.banner-upload button:disabled {
	opacity: 0.5;
	cursor: not-allowed;
}

.event-form {
	width: 100%;
}
.event-form label {
	display: block;
	margin-bottom: 10px;
	font-family: 'Lato', sans-serif;
	font-weight: 500;
	font-size: 15px;
	line-height: 20px;
	color: #263238;
}

.event-form input,
.event-form textarea,
.event-form select {
	width: 250px;
	background: rgba(233, 236, 242, 0.4);
	border: 1px solid #e9ecf2;
	box-sizing: border-box;
	border-radius: 12px;
	outline: none;
	font-family: 'Lato', sans-serif;
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
	color: rgba(69, 68, 162, 0.6);
	padding: 15px 16px;
	margin-bottom: 10px;
}

.event-form input::placeholder,
.event-form textarea::placeholder {
	font-family: 'Lato', sans-serif;
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
	color: rgba(69, 68, 162, 0.6);
}

.event-form input[type='date'],
.event-form input[type='time'],
.event-form select {
	cursor: pointer;
}

.event-form button {
	background: #4544a2;
	color: #fff;
	border-radius: 12px;
	font-family: 'Lato', sans-serif;
	font-weight: 500;
	font-size: 17px;
	line-height: 100%;
	padding: 15px 16px;
	border: none;
	outline: none;
	cursor: pointer;
	margin-top: 10px;
}

.event-tags-display {
	padding-left: 20px;
	margin-bottom: 5px;
}
.event-tags-display span {
	background-color: #6871e9;
	color: rgba(255, 255, 255, 0.5);
	font-family: 'Lato', sans-serif;
	font-weight: 600;
	font-size: 13px;
	padding: 6px;
	border-radius: 7px;
	margin-right: 5px;
}
.event-tags-display span i {
	padding-left: 5px;
	color: #fff;
	cursor: pointer;
}

/* Create Forum Modal */
.forum-modal-content {
	width: 100%;
}

.forum-modal-content h1 {
	text-align: center;
	margin-bottom: 25px;
	font-family: 'DM Serif Display', sans-serif;
	font-weight: 400;
	font-size: 24px;
	line-height: 100%;
	color: #2e353a;
}

.forum-form {
	width: 100%;
}

.forum-form label {
	display: block;
	margin-bottom: 10px;
	font-family: 'Lato', sans-serif;
	font-weight: 500;
	font-size: 15px;
	line-height: 20px;
	color: #263238;
}

.forum-form input,
.forum-form textarea,
.forum-form select {
	width: 250px;
	background: rgba(233, 236, 242, 0.4);
	border: 1px solid #e9ecf2;
	box-sizing: border-box;
	border-radius: 12px;
	outline: none;
	font-family: 'Lato', sans-serif;
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
	color: rgba(69, 68, 162, 0.6);
	padding: 15px 16px;
	margin-bottom: 10px;
}

.forum-form input::placeholder,
.forum-form textarea::placeholder {
	font-family: 'Lato', sans-serif;
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
	color: rgba(69, 68, 162, 0.6);
}

.forum-form input[type='date'],
.forum-form input[type='time'],
.forum-form select {
	cursor: pointer;
}

.forum-form button {
	background: #4544a2;
	color: #fff;
	border-radius: 12px;
	font-family: 'Lato', sans-serif;
	font-weight: 500;
	font-size: 17px;
	line-height: 100%;
	padding: 15px 16px;
	border: none;
	outline: none;
	cursor: pointer;
	margin-top: 10px;
}

.forum-tags-display {
	padding-left: 20px;
	margin-bottom: 5px;
}
.forum-tags-display span {
	background-color: #6871e9;
	color: rgba(255, 255, 255, 0.5);
	font-family: 'Lato', sans-serif;
	font-weight: 600;
	font-size: 13px;
	padding: 6px;
	border-radius: 7px;
	margin-right: 5px;
}
.forum-tags-display span i {
	padding-left: 5px;
	color: #fff;
	cursor: pointer;
}
