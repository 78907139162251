.noshow {
    display: none;
}

.show {
    display: block;
}

.haze {
    opacity: 0.5;
}

.nohaze {
    opacity: 1;
}

.chbx {
    width: 20px;
    height: 20px;
}

table {
    width: 100%;
    overflow-x: auto;
}

th {
    /* display: flex;
    align-items: center;
    justify-content: space-around; */
    /* word-break: break-all; */
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    color: #2e3b52;
    margin-top: 15px;
    text-align: center;
}

.mainheadd {
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    text-align: right;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #606f89;
}

th > td > pre {
    display: flex;
    align-items: center;
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    text-align: right;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #606f89;
}

th > td > div > p {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    color: #606f89;
}

th > td > div > span {
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #2e3b52;
}

td {
    padding: 5px 13px 5px 20px;
    height: 30px;
    /* word-break: break-all; */
    text-align: center;
}

td > div {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.size {
    width: 40px;
}

/* Interview selection modal */
.interview-modal {
    border: none;
    width: 100%;
    text-align: center;
}
.interview-modal h1 {
    font-family: 'DM Serif Display', sans-serif;
    font-weight: 400;
    font-size: 30px;
    line-height: 111%;
    color: #4544a2;
    text-align: center;
    margin-bottom: 10px;
}
.interview-modal p {
    font-family: 'Lato', sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 111%;
    color: rgba(69, 68, 162, 0.5);
    text-align: center;
    margin-bottom: 10px;
}
.interview-modal input,
.interview-modal select {
    background: rgba(233, 236, 242, 0.4);
    border: 1px solid #e9ecf2;
    box-sizing: border-box;
    border-radius: 12px;
    outline: none;
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 111%;
    color: rgba(69, 68, 162, 0.5);
    display: block;
    padding: 20px 25px;
    margin: 0 auto;
    margin-bottom: 10px;
    width: 300px;
}
.interview-modal button {
    background: #4544a2;
    border-radius: 8px;
    font-family: 'Lato', sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 111%;
    text-align: center;
    color: #ffffff;
    padding: 15px 20px;
    border: none;
    outline: none;
    cursor: pointer;
    width: fit-content;
    margin: 0 auto;
}

.link {
    /* color: #4544A2; */
    text-decoration: underline;
    margin-left: 5px;
}
