.blog-card {
  background: #FFFFFF;
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  margin: 2rem;
  /* z-index: 99; */
  /* margin-top: 6rem; */
  /* width: 58rem; */
}

/* @media (max-width: 960px) {
  .blog-card {
    width: 90%;
    margin: 4rem 0;
  }
} */


@media (max-width: 500px) {
  .blog-card {
   display: flex;
   flex-direction: column ;
  }
}
