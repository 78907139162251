.blog-heading {
  margin: 2rem 0rem 0rem 0rem;
}

.blog-heading{
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  /* identical to box height */
  
  letter-spacing: 0.075em;
  
  color: #000000;
}
.blog-item-even{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  
}

@media (max-width:500px){
  ul{
    padding: 0;
  }
}