.AdminSkillTests {
	/* min-width: 90%; */
	width: auto;
	height: calc(80vh);
	padding-left: 20px;
	padding-top: 33px;
	padding-bottom: 50px;
	padding-right: 10px;
	overflow-y: scroll;
}

/* width */
.AdminSkillTests::-webkit-scrollbar {
	width: 6px;
}

/* Track */
.AdminSkillTests::-webkit-scrollbar-track {
	background: #f1f1f1;
}

/* Handle */
.AdminSkillTests::-webkit-scrollbar-thumb {
	background: rgba(0, 0, 0, 0.4);
	border-radius: 15px;
}

/* Handle on hover */
.AdminSkillTests::-webkit-scrollbar-thumb:hover {
	background: #555;
}

.AdminSkillTests .SkillTest-card {
	min-width: 100%;
	width: fit-content;
}

.card-content {
	padding-left: 20px;
	padding-right: 10px;
}

.card-content a {
	color: #4544a2;
}

.admin-tests-header {
	margin-bottom: 30px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding-right: 10px;
	padding-left: 10px;
}
.admin-tests-header h1 {
	font-family: 'Lato', sans-serif;
	font-weight: 700;
	font-size: 20px;
	line-height: 24px;
	color: #2e353a;
}
.admin-tests-header button {
	border: 1px solid #4544a2;
	box-sizing: border-box;
	border-radius: 40px;
	background: none;
	font-family: 'Lato', sans-serif;
	font-weight: 400;
	font-size: 14px;
	line-height: 17px;
	color: #4544a2;
	padding: 10px 20px;
	cursor: pointer;
}

/* Create Skill Test Form */
.SkillTestForm {
	padding: 0;
	padding-right: 50px;
	width: 100%;
}

.SkillTestForm h1 {
	font-family: 'Lato', sans-serif;
	font-weight: 700;
	font-size: 20px;
	line-height: 24px;
	color: #2e353a;
	margin-bottom: 20px;
}

.create-test-form {
	width: 100%;
	position: relative;
}

.input-label {
	display: block;
	margin-bottom: 10px;
	font-family: 'Lato', sans-serif;
	font-weight: 500;
	font-size: 16px;
	line-height: 20px;
	color: #000;
}

.test-name-input,
.question-text,
.create-test-form select,
.create-test-form textarea {
	background: rgba(233, 236, 242, 0.4);
	border: 1px solid #e9ecf2;
	box-sizing: border-box;
	border-radius: 12px;
	outline: none;
	padding: 16px 16px;
	font-family: 'Lato', sans-serif;
	font-weight: 500;
	font-size: 16px;
	line-height: 20px;
	color: #4544a2;
	width: 300px;
}

.create-test-form input::placeholder,
.create-test-form textarea::placeholder {
	color: rgba(69, 68, 162, 0.3);
}

.create-test-form select {
	color: rgba(69, 68, 162, 0.6);
}

.level-btns span label {
	padding-left: 10px;
	padding-right: 40px;
	color: rgba(38, 50, 56, 0.5);
	font-size: 17px;
}

.level-btns span input {
	accent-color: #4544a2;
}

.job-types-display {
	margin-top: 10px;
}
.job-types-display span {
	background: #6871e9;
	border-radius: 7px;
	font-family: 'Lato', sans-serif;
	font-weight: 600;
	font-size: 13px;
	line-height: 21px;
	color: rgba(255, 255, 255, 0.5);
	padding: 7px 14px;
	margin-right: 5px;
}
.job-types-display span i {
	cursor: pointer;
	color: #fff;
	padding-left: 15px;
}

.add-questions {
	border-top: 1px solid #d7def0;
	margin-top: 20px;
	padding-top: 20px;
}

.question-div {
	display: flex;
	margin-bottom: 30px;
}

.question-text {
	display: block;
	margin-bottom: 22px;
}
.option-input {
	display: block;
	margin-bottom: 16px;
	border: none;
	outline: none;
	border-bottom: 1px solid rgba(69, 68, 162, 0.3);
	font-family: 'Lato', sans-serif;
	font-weight: 500;
	font-size: 17px;
	line-height: 20px;
	color: #4544a2;
}

.question-div div {
	width: 50%;
}

.question-div div span {
	display: flex;
}
.question-div div span i {
	color: #e9ecf2;
	padding-right: 10px;
}

.question-div h1 {
	font-size: 17px;
	line-height: 20px;
	margin-bottom: 10px;
}

.question-btns {
	display: flex;
}
.question-btns button {
	margin-right: 10px;
	background: #6871e9;
	border: none;
	outline: none;
	cursor: pointer;
	padding: 10px;
	border-radius: 15px;
	color: #fff;
	font-size: 12px;
}

.question-div div input[type="radio"] {
	accent-color: #4544a2;
}
.correct-option-label {
	font-size: 17px;
	line-height: 20px;
	color: rgba(38, 50, 56, 0.5);
	margin-right: 30px;
	padding-left: 10px;
}

.new-question-btn {
	background: #4544a2;
	border-radius: 10px;
	border: none;
	outline: none;
	cursor: pointer;
	font-family: 'Lato', sans-serif;
	font-weight: 500;
	font-size: 12px;
	line-height: 100%;
	color: #fff;
	padding: 10px 15px;
}

.submit-btns {
	width: 90%;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	margin-top: 10px;
}
.submit-btns button {
	margin-right: 20px;
	cursor: pointer;
	background: #4544a2;
	box-shadow: 0px 4px 5px rgba(69, 68, 162, 0.25);
	border-radius: 12px;
	font-family: 'Lato', sans-serif;
	font-weight: 500;
	font-size: 18px;
	line-height: 100%;
	padding: 15px 20px;
	color: #fff;
	border: none;
	outline: none;
}
