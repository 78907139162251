.SkillTestInfo {
	overflow-x: hidden;
	padding-bottom: 20px;
}

.filler-div {
	height: var(--nav-height);
}

.test-banner {
	height: 240px;
	width: 100%;
	background: linear-gradient(321.69deg, #ff6188 0%, #ffb199 100%);
	color: #fff;
	padding-top: 64px;
	padding-left: 70px;
}
.test-banner h1 {
	font-family: 'DM Serif Display', sans-serif;
	font-weight: 400;
	font-size: 50px;
	line-height: 38px;
	margin-bottom: 11px;
}
.test-banner p {
	font-family: 'Open Sans', sans-serif;
	font-weight: 600;
	font-size: 15px;
	line-height: 20px;
	margin-bottom: 50px;
}
.test-banner a {
	font-family: 'Lato', sans-serif;
	font-weight: 700;
	font-size: 20px;
	line-height: 25px;
	color: #ff9192;
	background: #ffffff;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
	border-radius: 10px;
	padding: 15px 45px;
	border: none;
	outline: none;
	cursor: pointer;
}

@media only screen and (max-width: 768px) {
	.test-banner {
		height: 227px;
		padding-top: 23px;
		padding-left: 37px;
		padding-right: 18px;
	}
	.test-banner h1 {
		font-size: 25px;
		line-break: auto;
		width: 90%;
	}
	.test-banner button {
		font-size: 16px;
		padding: 12px 37px;
	}
}

.test-info {
	padding-top: 50px;
	padding-left: 74px;
	padding-right: 110px;
	border-bottom: 1px solid rgba(38, 50, 56, 0.1);
}
.test-info h2 {
	font-family: 'Lato', sans-serif;
	font-weight: 700;
	font-size: 25px;
	line-height: 30px;
	color: #263238;
	margin-bottom: 20px;
}
.test-info p {
	font-family: 'Lato', sans-serif;
	font-weight: 400;
	font-size: 20px;
	line-height: 34px;
	color: rgba(38, 50, 56, 0.8);
	margin-bottom: 26px;
	width: 95%;
}
.test-info ul {
	display: flex;
	list-style: none;
	margin-bottom: 30px;
}
.test-info ul li {
	font-family: 'Open Sans', sans-serif;
	font-weight: 400;
	font-size: 15px;
	line-height: 20px;
	margin-right: 40px;
	color: rgba(38, 50, 56, 0.8);
	border: 1px solid rgba(38, 50, 56, 0.8);
	border-radius: 15px;
	padding: 2px 10px;
}

@media only screen and (max-width: 768px) {
	.test-info {
		padding-top: 50px;
		padding-left: 23px;
		padding-right: 23px;
	}
	.test-info h2 {
		font-size: 20px;
		margin-bottom: 12px;
	}
	.test-info p {
		font-size: 16px;
	}
	.test-info ul {
		flex-direction: column;
	}
	.test-info ul li {
		font-size: 15px;
		width: fit-content;
		margin-bottom: 15px;
	}
}

.related-info {
	padding-top: 50px;
	padding-left: 74px;
	padding-right: 110px;
}
.related-info h2 {
	margin-bottom: 20px;
}
.course-card {
	display: flex;
	height: 220px;
	width: 760px;
	border: 1px solid #d7def0;
	box-sizing: border-box;
	border-radius: 16px;
	padding-top: 42px;
	padding-left: 25px;
	margin-bottom: 25px;
}
.course-card img {
	height: 140px;
	width: 130px;
	border-radius: 7px;
}
.course-desc {
	padding-left: 24px;
	padding-right: 25px;
	width: 390px;
}
.course-desc h1 {
	font-family: 'DM Serif Display', sans-serif;
	font-weight: 400;
	font-size: 22px;
	line-height: 25px;
	margin-bottom: 8px;
	color: #081f32;
}
.course-desc p {
	font-family: 'Lato', sans-serif;
	font-weight: 400;
	font-size: 16px;
	line-height: 25px;
	color: #6e798c;
}
.course-info p {
	margin-bottom: 18px;
	font-family: 'Open Sans', sans-serif;
	font-weight: 600;
	font-size: 16px;
	line-height: 25px;
	color: #081f32;
}
.course-info p i {
	padding-right: 16px;
	color: #6e798c;
}

.testimonial-card {
	width: 340px;
	height: 240px;
	border: 1px solid #d7def0;
	box-sizing: border-box;
	border-radius: 15px;
	padding-left: 16px;
	padding-top: 20px;
	padding-right: 26px;
	margin-right: 40px;
}
.testimonial-card span {
	display: flex;
	align-items: center;
	margin-bottom: 12px;
}
.testimonial-card span img {
	width: 48px;
	height: 48px;
	border-radius: 5px;
}
.testimonial-card span div {
	padding-left: 12px;
}
.testimonial-card span div h1 {
	font-family: Inter;
	font-weight: 700;
	font-size: 18px;
	line-height: 120%;
}
.testimonial-card span div p {
	font-family: Inter;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 120%;
	color: rgba(60, 60, 67, 0.85);
}
.testimonial-card span i {
	margin-right: 5px;
	color: #d1d1d6;
}
.testimonial-card span i.selected {
	color: #ff9500;
}

@media only screen and (max-width: 768px) {
	.related-info {
		padding-top: 31px;
		padding-left: 23px;
		padding-right: 34px;
	}
	.related-info h2 {
		font-size: 20px;
	}

	.course-card {
		height: 270px;
		width: 306px;
		padding-top: 25px;
		padding-left: 23px;
		padding-right: 26px;
		margin: auto;
		margin-bottom: 25px;
	}
	.course-card img,
	.course-info {
		display: none;
	}
	.course-desc {
		padding-left: 0;
		padding-right: 0;
		width: 250px;
	}
	.course-desc p {
		margin-bottom: 15px;
	}
}
