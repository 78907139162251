.AdminInterviewRequests {
    height: calc(80vh);
    width: auto;
    padding-top: 13px;
    padding-left: 40px;
    overflow-y: auto;
}

/* width */
.AdminInterviewRequests::-webkit-scrollbar {
    width: 6px;
}

/* Track */
.AdminInterviewRequests::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
.AdminInterviewRequests::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.4);
    border-radius: 15px;
}

/* Handle on hover */
.AdminInterviewRequests::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.AdminInterviewRequests h1 {
    font-family: 'Lato', sans-serif;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #2e353a;
    margin-bottom: 28px;
}

.AdminCandidateList {
    height: calc(80vh);
    width: auto;
    padding-top: 13px;
    padding-left: 40px;
    overflow-y: auto;
}

.AdminCandidateList h1 {
    font-family: 'Lato', sans-serif;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #2e353a;
    margin-bottom: 28px;
}

.interview-requests {
    width: auto;
    margin: 0 auto;
}

.interview-request-card {
    display: flex;
    border: 1px solid #d7def0;
    box-sizing: border-box;
    border-radius: 15px;
    padding: 14px 19px;
    font-family: 'Lato', sans-serif;
}
.interview-request-card h2 {
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    color: #2e353a;
}

.admin-card {
    display: flex;
    border: 1px solid #d7def0;
    box-sizing: border-box;
    border-radius: 15px;
    padding: 14px 19px;
    font-family: 'Lato', sans-serif;
}
.admin-card h2 {
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    color: #2e353a;
}
.request-card-info p {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: rgba(46, 53, 58, 0.7);
}
.request-card-info button {
    margin-top: 10px;
    color: #4544a2;
    background: none;
    border: none;
    outline: none;
    font-family: 'Lato', sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    cursor: pointer;
}

.admin-card-info p {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: rgba(46, 53, 58, 0.7);
}
.admin-card-info button {
    margin-top: 10px;
    padding: 1rem;
    color: #4544a2;
    background: none;
    border: none;
    outline: none;
    font-family: 'Lato', sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    cursor: pointer;
}

.request-card-btns {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    padding-left: 10px;
}
.request-card-btns i {
    color: #fb174e;
    cursor: pointer;
    font-size: 20px;
}
.request-card-btns span {
    display: flex;
}
.request-card-btns span i:first-of-type {
    color: #42ba96;
    padding-right: 20px;
}
.request-card-btns button {
    background: #4544a2;
    border-radius: 5px;
    border: none;
    outline: none;
    cursor: pointer;
    font-family: 'Lato', sans-serif;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #fff;
    padding: 5px 10px;
}
.request-card-btns button i {
    color: #fff;
    padding-right: 10px;
    display: inline;
    font-size: 14px;
}

.dialog-btns button {
    margin-right: 20px;
}

/* Candidate Profile */
.CandidateProfile {
    position: relative;
    height: calc(75vh);
    width: auto;
    padding-top: 50px;
    padding-left: 35px;
    overflow-y: scroll;
}

/* width */
.CandidateProfile::-webkit-scrollbar {
    width: 6px;
}

/* Track */
.CandidateProfile::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
.CandidateProfile::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.4);
    border-radius: 15px;
}

/* Handle on hover */
.CandidateProfile::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.close-profile {
    position: absolute;
    top: 10px;
    right: 8%;
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
}
.close-profile i {
    color: #fb174e;
    font-size: 25px;
}

.profile-overview {
    display: flex;
    font-family: 'Lato', sans-serif;
}
.profile-overview img {
    width: 150px;
    height: 150px;
    border-radius: 15px;
}
.profile-overview span {
    padding-left: 30px;
}
.profile-overview span h1 {
    font-weight: 700;
    font-size: 40px;
    line-height: 48px;
    color: #2e353a;
    margin-bottom: 7px;
}
.profile-overview span p {
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: rgba(38, 50, 56, 0.5);
    margin-bottom: 5px;
}

.candidate-details {
    margin-top: 40px;
    display: flex;
}

.details-section-1 {
    width: 50%;
    border-right: 1px solid #d7def0;
    padding-right: 30px;
}
.details-section-2 {
    width: 50%;
    padding-left: 20px;
    padding-right: 10px;
}

.candidate-details h2 {
    font-family: 'Lato', sans-serif;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    color: rgba(69, 68, 162, 0.3);
    border-bottom: 1px solid #e9ecf2;
    margin-bottom: 10px;
    margin-top: 35px;
}

.edu-details p {
    font-family: 'Lato', sans-serif;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #2e353a;
    margin-bottom: 5px;
}

.preference-details p {
    font-family: 'Lato', sans-serif;
    font-weight: 600;
    font-size: 19px;
    line-height: 24px;
    color: #2e353a;
    margin-bottom: 8px;
}
.preference-details p span {
    font-weight: 400;
    color: rgba(46, 53, 58, 0.7);
}

.experience-details p {
    font-family: 'Lato', sans-serif;
    font-weight: 400;
    font-size: 16px;
    color: rgba(38, 50, 56, 0.7);
}
.experience-details p:first-of-type {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #2e353a;
    margin-bottom: 5px;
}

.skill-details ul {
    list-style: none;
}
.skill-details ul li {
    font-family: 'Lato', sans-serif;
    font-weight: 400;
    font-size: 19px;
    line-height: 24px;
    color: rgba(38, 50, 56, 0.7);
    margin-bottom: 8px;
}
.skill-details ul li span {
    color: #2e353a;
    font-weight: 600;
}

.project-details .project-card span {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
}
.project-details .project-card span h3 {
    font-family: 'Lato', sans-serif;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: #2e353a;
}
.project-details .project-card span a i {
    color: #6871e9;
}
.project-details .project-card p {
    font-family: 'Lato', sans-serif;
    font-weight: 400;
    font-size: 12px;
    line-height: 130%;
    color: rgba(46, 53, 58, 0.7);
}

@media only screen and (max-width: 992px) {
    .CandidateProfile {
        height: calc(70vh);
    }
}

@media only screen and (max-width: 768px) {
    .CandidateProfile {
        padding-left: 20px;
    }
    .candidate-details {
        margin-top: 0;
        flex-direction: column;
    }
    .details-section-1 {
        width: auto;
        border-right: none;
        padding-right: 20px;
    }
    .details-section-2 {
        width: auto;
        padding-left: 0;
        padding-right: 20px;
    }
}
