.show{
    display: block;
}

.noshow{
    display: none;
}

.smiley{
    z-index: 11;
}

.thumb{
    z-index: 10;
}

.heart{
    z-index: 9;
}
