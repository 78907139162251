.details-modal-header {
	font-family: 'DM Serif Display';
	font-weight: 400;
	font-size: 30px;
	line-height: 111%;
	color: #fff;
	text-align: center;
	margin-bottom: 20px;
}

.details-modal-contents {
	padding: 0 20px;
}

.details-modal-contents div {
	margin-bottom: 15px;
}

.details-modal-contents div h2 {
	font-family: 'Lato';
	font-weight: 600;
	font-size: 21px;
	line-height: 111%;
	color: #fff;
	margin-bottom: 6px;
}

.details-modal-contents div p {
	font-family: 'Lato';
	font-weight: 400;
	font-size: 16px;
	line-height: 111%;
	color: #fff;
	margin-left: 10px;
	white-space: pre-wrap;
}

.details-modal-contents div ul {
	list-style: none;
}

.details-modal-contents div ul li {
	margin-left: 10px;
	margin-bottom: 4px;
	font-family: 'Lato';
	font-weight: 400;
	font-size: 16px;
	line-height: 111%;
	color: #fff;
}

.styled-hr-2 {
	border: 0;
	height: 1px;
	background-image: linear-gradient(
		to right,
		rgba(255, 255, 255, 0),
		rgba(255, 255, 255, 0.75),
		rgba(255, 255, 255, 0)
	);
}
