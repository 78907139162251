.SkillTests {
	min-height: calc(100vh);
	overflow-x: hidden;
}

.filler-div {
	height: var(--nav-height);
}

.SkillTests-banner {
	height: 65px;
	width: 100%;
	background: linear-gradient(109.88deg, #6871e9 0%, #4544a2 73.54%);
	padding-top: 20px;
	padding-left: 31px;
	color: #fff;
}
.SkillTests-banner h1 {
	font-family: 'Lato', sans-serif;
	font-weight: 700;
	font-size: 20px;
	line-height: 100%;
	margin-bottom: 10px;
}
.SkillTests-banner p {
	font-family: 'Lato', sans-serif;
	font-weight: 400;
	font-size: 15px;
	line-height: 100%;
}

@media only screen and (max-width: 768px) {
	.SkillTests-banner {
		padding-left: 23px;
		padding-top: 25px;
		height: 90px;
	}
	.SkillTests-banner h1 {
		font-size: 18px;
	}
	.SkillTests-banner p {
		font-size: 14px;
		line-break: auto;
		width: 280px;
		line-height: 20px;
	}
}

/* SkillTests Content */
.SkillTests-content {
	display: flex;
	position: relative;
}
.SkillTests-sidebar {
	width: 380px;
	background: rgba(233, 236, 242, 0.4);
	height: calc(60vh + 50px);
	padding-left: 30px;
	padding-top: 60px;
	padding-right: 32px;
	display: block;
}

.search-filter {
	width: 100%;
	margin-bottom: 15px;
}
.search-filter div {
	display: flex;
	justify-content: space-between;
}
.search-filter h1 {
	font-family: 'Lato', sans-serif;
	font-weight: 700;
	font-size: 16px;
	line-height: 16px;
	color: #263238;
	margin-bottom: 15px;
}
.search-filter p {
	font-family: 'Lato', sans-serif;
	font-weight: 600;
	font-size: 16px;
	line-height: 16px;
	color: #4544a2;
	cursor: pointer;
}
.search-filter input {
	width: 100%;
	background: #ffffff;
	border: 1px solid #d7def0;
	box-sizing: border-box;
	border-radius: 10px;
	outline: none;
	height: 50px;
	font-family: 'Lato', sans-serif;
	font-weight: 500;
	font-size: 17px;
	line-height: 20px;
	color: rgba(69, 68, 162, 0.3);
	padding: 15px;
}

.roles-filter {
	margin-bottom: 15px;
}
.roles-filter h1 {
	font-family: 'Lato', sans-serif;
	font-weight: 700;
	font-size: 16px;
	line-height: 100%;
	color: #263238;
	margin-bottom: 15px;
}
.roles-select {
	background-color: #fff;
	outline: none;
	border: 1px solid #d7def0;
	box-sizing: border-box;
	border-radius: 10px;
	height: 50px;
}

.levels-filter {
	display: block;
	align-items: center;
}
.levels-filter h1 {
	font-family: 'Lato', sans-serif;
	font-weight: 700;
	font-size: 16px;
	line-height: 16px;
	color: #263238;
	margin-bottom: 15px;
}
.levels-filter label {
	font-family: 'Lato', sans-serif;
	font-weight: 500;
	font-size: 17px;
	line-height: 20px;
	color: #4544a2;
	margin-bottom: 15px;
	margin-left: 15px;
}
.levels-filter span {
	display: block;
	margin-bottom: 15px;
	align-items: center;
}
.levels-filter input {
	accent-color: #4544a2;
}

.filters-btn {
	display: none;
	width: 94px;
	height: 36px;
	font-family: 'Lato', sans-serif;
	font-weight: 600;
	font-size: 16px;
	line-height: 100%;
	color: #4544a2;
	background: #e9ecf2;
	border-radius: 8px;
	border: none;
	outline: none;
	cursor: pointer;
	position: absolute;
	right: 20px;
	top: 10px;
}

@media only screen and (max-width: 992px) {
	body {
		overflow-y: scroll;
	}
	.SkillTests-content {
		display: flex;
		flex-direction: column;
	}
	.SkillTests-sidebar {
		width: 80%;
		background: none;
		height: 100%;
		padding-left: 30px;
		padding-top: 60px;
		padding-right: 32px;
		margin: 0 auto;
		margin-bottom: 40px;
		display: none;
	}
	.filters-btn {
		display: block;
	}
}

/* Skill Test Cards List */
.SkillTests-list {
	width: calc(100vw - 380px);
	height: 60vh;
	overflow-y: scroll;
	padding: 50px;
}

/* width */
.SkillTests-list::-webkit-scrollbar {
	width: 6px;
}

/* Track */
.SkillTests-list::-webkit-scrollbar-track {
	background: #f1f1f1;
}

/* Handle */
.SkillTests-list::-webkit-scrollbar-thumb {
	background: rgba(0, 0, 0, 0.4);
	border-radius: 15px;
}

/* Handle on hover */
.SkillTests-list::-webkit-scrollbar-thumb:hover {
	background: #555;
}

.SkillTest-card {
	height: 240px;
	width: 100%;
	border-radius: 10px;
	display: flex;
	flex-direction: row;
	box-shadow: 2px 0px 21px -1px rgba(0, 0, 0, 0.37);
	-webkit-box-shadow: 2px 0px 21px -1px rgba(0, 0, 0, 0.37);
	-moz-box-shadow: 2px 0px 21px -1px rgba(0, 0, 0, 0.37);
	margin-bottom: 23px;
}
.card-banner img {
	height: 100%;
	border-top-left-radius: 10px;
	border-bottom-left-radius: 10px;
}

.card-content {
	padding-left: 60px;
	padding-top: 20px;
	width: 100%;
	padding-right: 30px;
	position: relative;
}
.card-content p:first-child {
	font-family: 'Open Sans', sans-serif;
	font-weight: 600;
	font-size: 13px;
	line-height: 17px;
	color: #6e798c;
	margin-bottom: 14px;
}
.card-content h1 {
	font-family: 'DM Serif Display', sans-serif;
	font-weight: 400;
	font-size: 25px;
	line-height: 25px;
	color: #081f32;
	margin-bottom: 10px;
}
.card-content p {
	font-family: 'Lato', sans-serif;
	font-weight: 400;
	font-size: 15px;
	line-height: 22px;
	color: rgba(46, 53, 58, 0.7);
	margin-bottom: 18px;
}
.card-content ul {
	display: flex;
	list-style: none;
}
.card-content ul li {
	margin-right: 24px;
	border: 1px solid rgba(69, 68, 162, 0.5);
	border-radius: 15px;
	padding: 2px 10px;
	font-family: 'Open Sans', sans-serif;
	font-weight: 400;
	font-size: 11px;
	line-height: 15px;
	color: rgba(69, 68, 162, 0.5);
}
.card-content a {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	margin-top: 35px;
	font-family: 'Open Sans', sans-serif;
	font-weight: 600;
	font-size: 11px;
	line-height: 15px;
	color: #ff6188;
	position: absolute;
	right: 30px;
	bottom: 25px;
}
.card-content a i {
	padding-left: 2px;
}

.not-found {
	text-align: center;
	margin: auto;
	font-family: 'Open Sans', sans-serif;
	color: #6e798c;
}

@media only screen and (max-width: 992px) {
	.SkillTests-list {
		width: 100%;
		height: fit-content;
		padding: 10px;
		margin-top: 70px;
	}
	.SkillTest-card {
		flex-direction: column;
		height: fit-content;
		width: 400px;
		margin: 0 auto;
		margin-bottom: 20px;
	}
	.card-banner img {
		width: 100%;
		border-top-left-radius: 10px;
		border-top-right-radius: 10px;
		border-bottom-left-radius: 0;
		height: 160px;
	}
	.card-content {
		padding-left: 23px;
		padding-right: 30px;
		position: relative;
		width: fit-content;
	}
	.card-content p:first-child {
		font-size: 11px;
	}
	.card-content h1 {
		font-size: 20px;
	}
	.card-content p {
		font-size: 13px;
		line-break: auto;
		width: 100%;
	}
	.card-content a {
		position: relative;
	}
	.card-content ul li {
		line-height: 15px;
		font-size: 10px;
	}
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
	.SkillTests-list {
		padding: 10px;
	}
	.SkillTest-card {
		flex-direction: row;
		height: 240px;
		width: 800px;
		margin: 0 auto;
		margin-bottom: 20px;
	}
	.card-banner img {
		width: 100%;
		height: 100%;
		border-top-left-radius: 10px;
		border-bottom-left-radius: 10px;
		border-top-right-radius: 0;
	}
}
