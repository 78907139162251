.Events {
	/* min-height: calc(100vh); */
	height: 100%;
	margin: 0 3rem;
	padding-bottom: 20px;
}

.filler-div {
	height: var(--nav-height);
}

/* Filters section */
.Events-filters {
	width: 100%;
	display: flex;
	margin-top: 2.5rem;
	box-sizing: border-box;
}

.related-topics h1 {
	font-family: 'Lato', sans-serif;
	font-weight: 700;
	font-size: 20px;
	margin-bottom: 15px;
}

.related-topics button {
	outline: none;
	border: none;
	padding: 10px 20px;
	margin: 5px;
	border-radius: 7px;
	background-color: #e9ecf2;
	color: rgba(69, 68, 162, 0.3);
	transition: 0.5s;
	font-family: 'Lato', sans-serif;
	font-weight: 400;
	font-size: 16px;
	cursor: pointer;
}

.related-topics .btn-selected {
	background-color: #4544a2;
	color: #fff;
}

.search {
	margin-left: 30px;
}

.search h1 {
	font-family: 'Lato', sans-serif;
	font-weight: 700;
	font-size: 16px;
	color: #4544a2;
	margin-bottom: 12px;
	margin-top: 10px;
}

.search input {
	width: 280px;
	height: 40px;
	border-radius: 7px;
	padding: 0 10px;
	outline: none;
}
.input-icons {
	z-index: 10;
	color: #e9ecf2;
}

.search input:focus {
	outline: none;
}

/* Events Cards */
.Events-cards {
	margin-top: 50px;
}

.event-banner {
	background-position: center;
}

.event-content {
	padding: 20px;
}

.event-content .info {
	display: flex;
	justify-content: space-between;
	margin-bottom: 12px;
}
.event-content .info p:first-child {
	font-weight: 700;
}
.event-content .info p {
	font-family: 'Lato', sans-serif;
	font-weight: 400;
	font-size: 11px;
	color: #6e798c;
}

.event-content h1 {
	margin-bottom: 5px;
	font-family: 'DM Serif Display', sans-serif;
	font-weight: 400;
	font-size: 20px;
	color: #081f32;
	line-height: 25px;
}
.event-content p {
	font-family: 'Lato', sans-serif;
	font-weight: 400;
	font-size: 13px;
	color: #374a59;
	line-height: 19px;
}

.event-actions div {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
}

.event-actions div p {
	display: flex;
	align-items: center;
	font-family: 'Open Sans', sans-serif;
	font-weight: 600;
	font-size: 12px;
	line-height: 15px;
	color: #007ae9;
}
.event-actions div p img {
	margin-right: 10px;
}

.event-actions div a {
	font-family: 'Open Sans', sans-serif;
	font-weight: 600;
	font-size: 12px;
	line-height: 15px;
	color: #007ae9;
}

.event-profile-pic {
	height: 30px;
	width: 30px;
	border-radius: 50%;
}

@media only screen and (max-width: 992px) {
	.Events-filters {
		display: block;
	}
	.search {
		margin-left: 5px;
		margin-top: 10px;
	}
	.search h1 {
		display: none;
	}
}

/* Event Info Page */
.Event-banner {
	height: 368px;
	width: 100%;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}

.Event-banner div {
	padding-top: 70px;
	padding-left: 60px;
	color: #fff;
}
.Event-banner div h1 {
	font-family: 'DM Serif Display', sans-serif;
	font-weight: 400;
	font-size: 40px;
	margin-bottom: 20px;
	line-height: 25px;
}
.Event-banner div p {
	font-family: 'Lato', sans-serif;
	font-weight: 700;
	font-size: 20px;
	line-height: 25px;
}

.Event-banner div ul {
	display: flex;
	list-style: none;
	margin: 20px 0;
	padding-bottom: 40px;
}
.Event-banner div ul li {
	font-family: 'Lato', sans-serif;
	font-weight: 400;
	font-size: 15px;
	line-height: 25px;
	color: rgba(255, 255, 255, 0.7);
	border: 1px solid rgba(255, 255, 255, 0.7);
	padding: 1px 10px;
	border-radius: 20px;
}

.Event-banner div button {
	font-family: 'Lato', sans-serif;
	font-weight: 400;
	font-size: 20px;
	line-height: 25px;
	color: #263238;
	padding: 15px 45px;
	background-color: #fff;
	border: none;
	outline: none;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
	border-radius: 10px;
	cursor: pointer;
}

.Event-info {
	margin-top: 50px;
	margin-left: 60px;
	padding-bottom: 20px;
}

.Event-info h2 {
	font-family: 'Lato', sans-serif;
	font-weight: 400;
	font-size: 25px;
	line-height: 25px;
	padding-bottom: 20px;
}
.Event-info p {
	font-family: 'Lato', sans-serif;
	font-weight: 400;
	font-size: 20px;
	line-height: 25px;
	color: rgba(46, 53, 58, 0.7);
}
.Event-info p:first-child {
	line-height: 30px;
}

@media only screen and (max-width: 992px) {
	.Event-banner div {
		padding-top: 35px;
		padding-left: 25px;
	}
	.Event-banner div h1 {
		line-height: 28px;
		font-size: 22px;
	}
	.Event-banner div p {
		font-size: 16px;
	}
	.Event-banner div button {
		font-size: 15px;
		padding: 13px 40px;
	}
	.Event-info {
		margin-top: 60px;
		margin-left: 30px;
	}
	.Event-info p {
		font-size: 16px;
	}
}

.event-banner-preview img {
	height: 300px;
	width: 300px;
	padding-right: 100px;
	padding-top: 20px;
	border-radius: 20px;
}