.Community {
    width: auto;
    height: 100%;
}

/* Hero Section */
.community-hero {
    height: calc(100vh - var(--nav-height));
    width: 100%;
    /* padding-left: 50px; */
    padding-right: 20px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.hero-banner {
    width: auto;
}
.hero-banner img {
    height: 350px;
}

.hero-text h1 {
    font-family: 'Lato', sans-serif;
    font-weight: 400;
    font-size: 28px;
    line-height: 48px;
    color: #4544a2;
}
.hero-text h1 span {
    font-weight: 600;
}
.hero-text button {
    background-color: #4544a2;
    color: #fff;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 20px 35px;
    border-radius: 15px;
    margin-top: 40px;
    font-family: 'Lato', sans-serif;
    font-weight: 500;
    font-size: 16px;
    line-height: 100%;
}

@media screen and (max-width:500px) {
    .community-events .events-text{
        padding: 1em !important;
    }
}

@media screen and (max-width: 768px) {
    .community-hero {
        flex-direction: column-reverse;
        align-items: flex-start;
        justify-content: start;
        padding-top: 50px;
        padding-left: 40px;
        padding-right: 0;
        height: 100%;
    }
    .hero-banner {
        margin-left: 2rem;
    }
    .hero-banner img {
        height: 250px;
        width: auto;
        margin: 20px auto;
    }
}

@media screen and (max-width: 576px) {
    .community-hero {
        padding-left: 0;
    }
    .hero-text {
        padding-left: 20px;
    }
    .hero-text h1 {
        font-size: 24px;
        line-height: 40px;
    }
    .hero-banner img {
        height: 150px;
    }
}

@media screen and (min-width: 768px) and (max-width: 992px) {
    .community-hero {
        padding-right: 20px;
        padding-left: 20px;
    }
    .hero-banner img {
        height: 200px;
    }
    .hero-text h1 {
        font-size: 25px;
    }
}

/* Features Section */
.community-features {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    border-top: 2px solid rgba(69, 68, 162, 0.3);
    border-bottom: 2px solid rgba(69, 68, 162, 0.3);
    box-sizing: border-box;
    padding: 30px 0;
    background-color: #4544a2;
}

.community-features p {
    font-family: 'Lato', sans-serif;
    font-weight: 400;
    font-size: 18px;
    line-height: 36px;
    color: #4544a2;
    background: #fff;
    border-radius: 10px;
    padding: 13px 23px;
    transition: all 500ms ease;
}
.community-features p i {
    padding-right: 10px;
}
.community-features p:hover {
    box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.11);
    transform: scale(1.1);
}

@media screen and (max-width: 768px) {
    .community-features {
        flex-direction: column;
        padding-right: 35px;
        padding-left: 35px;
    }
    .community-features p {
        text-align: center;
        margin-bottom: 16px;
    }
}

@media screen and (min-width: 768px) and (max-width: 992px) {
    .community-features p {
        font-size: 14px;
        font-weight: 600;
        padding: 10px 16px;
    }
}

/* Network Section */
.community-network {
    height: 80vh;
    /* width: 100%; */
    padding-left: 60px;
    padding-right: 60px;
    display: flex;
    justify-content: space-between;
    background: rgba(199, 199, 227, 0.24);
}

.network-text {
    align-self: center;
}
.network-text h1 {
    font-family: 'Lato', sans-serif;
    font-weight: 800;
    font-size: 32px;
    line-height: 48px;
    color: #4544a2;
    margin-bottom: 40px;
}
.network-text p {
    font-family: 'Lato', sans-serif;
    font-weight: 400;
    font-size: 24px;
    line-height: 36px;
    color: #263238;
}

.network-img {
    display: flex;
}

.network-img .row1,
.network-img .row3 {
    height: 100%;
    background: rgba(69, 68, 162, 0.09);
}
.network-img .row1 img,
.network-img .row3 img {
    margin-top: calc(70%);
    border: 4px solid #4544a2;
    border-radius: 20px;
}
.network-img .row2 {
    margin: 0 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}
.network-img .row2 img {
    border: 4px solid #4544a2;
    border-radius: 20px;
}

.network-img.mobile-view {
    display: none;
}
.mobile-view .row1 {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}
.mobile-view .row1 img {
    margin: 20px;
    height: 100px;
    border: 4px solid #4544a2;
    border-radius: 20px;
}

.company-logos {
    margin-top: 50px;
}
.company-logos span {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
}
.company-logos span img {
    height: 80px;
    width: 100px;
    margin-right: 100px;
}
.company-logos span p {
    font-size: 60px;
    font-weight: 600;
    /* padding-bottom: 50px; */
    display: inline-block;
}
/* .company-logos span {
	display: block;
	margin-bottom: 30px;
} */

@media screen and (max-width: 768px) {
    .community-network {
        flex-direction: column;
        height: 100%;
        padding-top: 30px;
        padding-left: 0;
        padding-right: 0;
    }
    .network-text h1 {
        font-size: 28px;
        margin-bottom: 20px;
    }
    .network-text p {
        font-size: 20px;
        line-height: 32px;
    }
    .network-text {
        width: 90vw;
    }
    .network-img {
        display: none;
    }
    .network-img.mobile-view {
        display: flex;
        justify-content: space-between;
        margin-top: 35px;
    }
    .network-img.mobile-view .row1 {
        padding-right: 80px;
    }
    .network-img.mobile-view #row1 {
        padding-left: 80px;
        padding-right: 0;
    }
    .company-logos span {
        margin-left: 20px;
    }
    .company-logos span img {
        height: 70px;
        width: 80px;
        margin-right: 50px;
    }
    .company-logos span p {
        display: none;
    }
}

@media screen and (max-width: 576px) {
    .network-img.mobile-view .row1 {
        padding-right: 0px;
    }
    .network-img.mobile-view #row1 {
        padding-left: 0px;
        padding-right: 0;
    }
    .network-text {
        padding-left: 20px;
    }
}

@media screen and (min-width: 768px) and (max-width: 992px) {
    .community-network {
        padding-left: 20px;
        padding-right: 20px;
    }
    .network-text h1 {
        font-size: 25px;
        margin-bottom: 20px;
    }
    .network-text p {
        font-size: 18px;
        line-height: 30px;
    }
    .network-img .row1 img,
    .network-img .row3 img,
    .network-img .row2 img {
        height: 160px;
    }
}

/* Events Section */
.community-events {
    height: 80vh;
    display: flex;
    justify-content: space-between;
    background-color: #4544a2;
    position: relative;
}
.community-events:before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background-image: url('../assets/illustrations/simple_process_bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    opacity: 0.1;
    z-index: 0;
}

.community-events .events-text {
    padding-top: 60px;
    padding-left: 40px;
    color: #fff;
}
.community-events .events-text h1 {
    font-family: 'Lato', sans-serif;
    font-weight: 400;
    font-size: 48px;
    line-height: 72px;
    margin-bottom: 20px;
}
.community-events .events-text h1 span {
    font-weight: 600;
}
.community-events .events-text p {
    font-family: 'Lato', sans-serif;
    font-weight: 400;
    font-size: 24px;
    line-height: 36px;
}

.community-events .events-banner {
    z-index: 20;
}
.community-events .events-banner img {
    height: 100%;
}

@media screen and (max-width: 768px) {
    .community-events {
        flex-direction: column;
        height: 100%;
    }
    .community-events .events-text {
        padding-top: 40px;
        padding-left: 50px;
    }
    .community-events .events-text h1 {
        font-size: 30px;
        line-height: 40px;
        margin-bottom: 30px;
    }
    .community-events .events-text p {
        font-size: 20px;
        line-height: 30px;
    }
    .community-events .events-banner {
        margin-top: 20px;
    }
    .community-events .events-banner img {
        height: 400px;
        width: 100%;
    }
}

@media screen and (min-width: 992px) and (max-width: 1200px) {
    .community-events .events-text h1 {
        font-size: 35px;
        line-height: 50px;
    }
    .community-events .events-text p {
        font-size: 20px;
    }
    .community-events .events-banner img {
        height: 100%;
        width: 500px;
    }
}

@media screen and (min-width: 768px) and (max-width: 992px) {
    .community-events .events-text h1 {
        font-size: 35px;
        line-height: 50px;
    }
    .community-events .events-text p {
        font-size: 20px;
    }
    .community-events .events-text {
        padding-left: 20px;
        padding-top: 30px;
    }
    .community-events .events-banner img {
        height: 100%;
        width: 400px;
    }
}

/* Interact Section */
.community-interact {
    height: 100%;
    display: flex;
    justify-content: space-between;
    background: rgba(199, 199, 227, 0.24);
    padding-right: 20px;
}

.interact-banner img {
    height: 100%;
}
.interact-banner button {
    display: none;
    background: #4544a2;
    color: #fff;
    border-radius: 15px;
    border: none;
    outline: none;
    cursor: pointer;
    font-family: 'Lato', sans-serif;
    font-weight: 500;
    font-size: 18px;
    line-height: 100%;
    padding: 18px 30px;
    margin: 10px auto;
}

.interact-text {
    padding-top: 120px;
    padding-left: 40px;
}
.interact-text p {
    font-family: 'Lato', sans-serif;
    font-weight: 400;
    font-size: 24px;
    line-height: 36px;
    color: #263238;
    margin-bottom: 45px;
}
.interact-text h1 {
    font-family: 'Lato', sans-serif;
    font-weight: 400;
    font-size: 32px;
    line-height: 48px;
    color: #4544a2;
    margin-bottom: 45px;
}
.interact-text h1 span {
    font-weight: 600;
}
.interact-text button {
    background: #4544a2;
    color: #fff;
    border-radius: 15px;
    border: none;
    outline: none;
    cursor: pointer;
    font-family: 'Lato', sans-serif;
    font-weight: 500;
    font-size: 18px;
    line-height: 100%;
    padding: 18px 30px;
    width: fit-content;
    margin: 0 auto;
    margin-left: 30%;
}

@media screen and (max-width: 768px) {
    .community-interact {
        height: 100%;
        flex-direction: column-reverse;
        padding-bottom: 40px;
        background-color: #eeeef7;
        justify-content: center;
        align-items: center;
    }
    .interact-text {
        padding-top: 50px;
        padding-left: 0;
    }
    .interact-text h1 {
        margin-bottom: 0;
        text-align: center;
    }
    .interact-text p {
        display: none;
    }
    .interact-text button {
        display: none;
    }
    .interact-banner img {
        margin: 0 auto;
        /* margin-left: 50px; */
        width: 500px;
    }
    .interact-banner button {
        display: block;
    }
}

@media screen and (max-width: 576px) {
    .community-interact {
        justify-content: center;
        align-items: center;
    }
    .interact-banner img {
        width: 350px;
        height: 100%;
        margin-left: 0;
    }
}

@media screen and (min-width: 992px) and (max-width: 1200px) {
    .interact-text {
        padding-top: 40px;
    }
    .interact-text p {
        margin-bottom: 40px;
        font-size: 22px;
        line-height: 30px;
    }
    .interact-text h1 {
        margin-bottom: 20px;
        font-size: 28px;
    }
    .interact-text button {
        font-size: 16px;
        padding: 16px 25px;
        border-radius: 18px;
        margin-left: 20%;
    }
}

@media screen and (min-width: 768px) and (max-width: 992px) {
    .interact-banner img {
        width: 450px;
        height: 100%;
    }
    .interact-text {
        padding-top: 40px;
        padding-left: 40px;
    }
    .interact-text p {
        margin-bottom: 40px;
        font-size: 22px;
        line-height: 30px;
    }
    .interact-text h1 {
        margin-bottom: 20px;
        font-size: 28px;
    }
    .interact-text button {
        font-size: 16px;
        padding: 16px 25px;
        border-radius: 18px;
    }
}
