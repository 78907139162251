.filler-div {
    height: var(--nav-height);
}

.SkillTest-content {
    display: flex;
}

/* Sidebar */
.question-panel {
    width: 350px;
    height: calc(100vh - var(--nav-height));
    background: #4544a2;
    padding-top: 45px;
    padding-left: 32px;
}
.question-panel h1 {
    font-family: 'Lato', sans-serif;
    font-weight: 700;
    font-size: 15px;
    line-height: 19px;
    color: #fff;
    margin-bottom: 23px;
}
.question-panel button {
    font-family: 'Lato', sans-serif;
    font-weight: 400;
    font-size: 15px;
    line-height: 19px;
    color: #4544a2;
    padding: 15px 20px;
    border-radius: 5px;
    background: #fff;
    border: none;
    outline: none;
    margin-right: 10px;
    margin-bottom: 10px;
    cursor: pointer;
}
.question-panel button.selected {
    background: #07da68;
    color: #fff;
}

.ques-panel-btn {
    display: none;
    font-family: 'Lato', sans-serif;
    font-weight: 500;
    font-size: 16px;
    line-height: 100%;
    color: #fff;
    background: #2e353a;
    border-radius: 14px;
    padding: 7px 15px;
    border: none;
    outline: none;
    cursor: pointer;
    width: fit-content;
    margin-bottom: 17px;
    margin-top: 17px;
    margin-left: 25px;
}

.ques-panel-btn i {
    margin-left: 5px;
}

/* Question Container */
.question-container {
    height: calc(100vh - var(--nav-height));
    width: calc(100% - 350px);
    padding-left: 35px;
    padding-top: 55px;
    padding-right: 110px;
    padding-bottom: 80px;
}

.question-info {
    display: flex;
    justify-content: space-between;
}
.question-info h1 {
    font-family: 'Lato', sans-serif;
    font-weight: 700;
    font-size: 20px;
    line-height: 19px;
    color: #4544a2;
    margin-bottom: 20px;
}

.question-container p {
    font-family: 'Lato', sans-serif;
    font-weight: 700;
    font-size: 20px;
    line-height: 19px;
    color: #4544a2;
    margin-bottom: 16px;
}
.styled-hr {
    border: 0;
    height: 0;
    border-top: 1px solid #e9ecf2;
    border-bottom: 1px solid #e9ecf2;
}

.question p {
    margin: 45px 0;
    color: #2e353a;
}
.question span {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;
}
.question span label {
    padding-left: 20px;
    font-family: 'Lato', sans-serif;
    font-weight: 500;
    font-size: 20px;
    line-height: 19px;
    color: rgba(46, 53, 58, 0.7);
}
.question span input {
    border: 2px solid rgba(46, 53, 58, 0.7);
    width: 30px;
    height: 30px;
    accent-color: #2e353a;
}

.question-nav-btns {
    margin-top: 120px;
    display: flex;
    justify-content: space-between;
    width: 80%;
}
.question-nav-btns button {
    font-family: 'Lato', sans-serif;
    font-weight: 500;
    font-size: 18px;
    line-height: 100%;
    color: #ffffff;
    background: #4544a2;
    border-radius: 12px;
    padding: 19px 36px;
    border: none;
    outline: none;
    cursor: pointer;
}

.dialog-title {
    font-family: 'Lato', sans-serif;
    font-weight: normal;
    font-size: 20px;
    line-height: 113.5%;
}
.dialog-btns button:first-child {
    background: #2e353a;
}
.dialog-btns button {
    font-family: 'Lato', sans-serif;
    font-weight: 500;
    font-size: 16px;
    line-height: 100%;
    background: #4544a2;
    border-radius: 15px;
    padding: 15px 30px;
    border: none;
    outline: none;
    color: #fff;
    cursor: pointer;
}

@media only screen and (max-width: 992px) {
    .question-nav-btns {
        margin-top: 70px;
    }
    body {
        overflow-y: scroll;
    }
}

@media only screen and (max-width: 768px) {
    .SkillTest-content {
        flex-direction: column;
        width: 100vw;
        position: relative;
    }

    .ques-panel-btn {
        display: block;
    }

    .question-panel {
        width: 100%;
        padding-top: 34px;
        padding-left: 25px;
        height: fit-content;
    }
    .question-panel h1 {
        display: none;
    }
    .question-panel button {
        margin-right: 20px;
        margin-bottom: 20px;
    }

    .question-container {
        width: 100%;
        padding-left: 23px;
        padding-top: 32px;
        padding-right: 0;
    }

    .question-info h1 {
        font-size: 16px;
    }
    .question-container p {
        font-size: 16px;
    }

    .question-nav-btns button {
        font-size: 17px;
        padding: 15px;
    }
}
