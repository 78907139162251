.blog_detail{
    height: 100%;
    /* display: flex; */
    /* justify-content: space-between; */
    /* align-items: center; */
    padding-top: 90px;
}


.details_Container{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.detail_image{
    width: 100%;
    height: 30rem;
  
}
.detail_image img{
    width: 100%;
    height: 30rem;
    position: absolute;
    z-index: -1;
}



.all_blogs{
    display: flex;
    flex-direction: column;
}

.blog_info{
    /* position:absolute; */
    /* top: 100%; */
    /* left: 0;  */
    /* right: 0;    */
    /* height: fit-content; */
    /* margin-left: auto; 
    margin-right: auto;  */
    width: 95%;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    overflow: hidden;
    word-wrap: break-word;
    clear: both;
    padding: 2rem 0rem;
    margin-top: -15rem;
}

.blog_heading{
    margin: 1rem 0rem;
    text-align: center;
}

.blog_heading h1{
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 43px;
    letter-spacing: 0.075em;
    color: #000000;
}

.blog_heading p{
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 32px;
    /* identical to box height */
    letter-spacing: 0.075em;
    color: #787878;
}

.blog_description {
    display: block;
    width: 90%;
    word-wrap: break-word;
    margin: 1rem 0rem;
}

.share_buttons{
    display: flex;
    justify-content: center;
    align-items: center;
    float: left;
    margin-right: 69%;
}

.share_buttons p:nth-child(1){
    margin: 0rem 1rem;
    font-style: normal;
    font-weight: 600;
    font-size: 26px;
    line-height: 35px;
    /* identical to box height */
    color: #FAB84C;
}
.share_buttons span{
    /* background: #000000; */
    
    margin: 0rem 1rem;
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    line-height: 35px;
    /* identical to box height */
    color: #000000;
}

.blogs_detail{
    height: 100%;
}
.bottom_cards h1{
    float: left;
}


.blog_cont{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}


.left_img{
    margin-right: 15rem;
}

.right_img{
    margin-left: 15rem;
}
.more_head h1{
    margin-top: 3rem;
    margin-bottom: 1rem;
    margin-left: 7rem;
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 37px;
    letter-spacing: 0.075em;
    
    color: #000000;
}


.blog_images{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 20%;
    height: 20%;
    /* margin: 1rem 0rem; */
  }
  
  .blog_images img{
    margin: 1rem 4rem;
    width:100%;
    height:100%;
  }

  .more_blogs{
      margin: 3rem 0rem;
  }
  .blog_image_middle{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 50%;
    height: 50%;
  }
  .blog_image_middle img{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 50%;
    height: 50%;
  }

  @media  (max-width: 500px) {
    .blog_heading h1{
        font-size: 25px;
        line-height: 30px;
        width: 100%;
    }
    .blog_images{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 50%;
        height: 50%;
    }
}