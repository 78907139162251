.noshow{
    display: none;
}

.show{
    display: block;
}

hr{
    width: 30vw;
    height: 1px;
    margin-top:20px;
    background-color: #4544A2;
    /* margin-left:-80px; */
}


.unit { 
    position: absolute; 
    display: block; 
    left: 5px; 
    top: 10px; 
    z-index: 9; 
  }

.options{
    padding: 20px 5px;
    border: 1px solid black;
}  
