.AdminMentorRequests {
	height: calc(80vh);
	padding-top: 15px;
	padding-left: 35px;
	width: auto;
	overflow-y: scroll;
}

/* width */
.AdminMentorRequests::-webkit-scrollbar {
	width: 6px;
}

/* Track */
.AdminMentorRequests::-webkit-scrollbar-track {
	background: #f1f1f1;
}

/* Handle */
.AdminMentorRequests::-webkit-scrollbar-thumb {
	background: rgba(0, 0, 0, 0.4);
	border-radius: 15px;
}

/* Handle on hover */
.AdminMentorRequests::-webkit-scrollbar-thumb:hover {
	background: #555;
}

.no-data-msg {
	color: #4544a2;
	font-weight: 600;
	font-size: 18px;
}

.AdminMentorRequests h1 {
	font-family: 'Lato', sans-serif;
	font-weight: 700;
	font-size: 20px;
	line-height: 24px;
	color: #2e353a;
	margin-bottom: 20px;
}

.mentor-request-card {
	position: relative;
	display: flex;
	justify-content: space-around;
	font-family: 'Lato', sans-serif;
	border: 1px solid #d7def0;
	box-sizing: border-box;
	border-radius: 15px;
	padding: 20px;
	width: auto;
}

.close-request-btn {
	position: absolute;
	top: 10px;
	right: 20px;
	background: none;
	border: none;
	outline: none;
	cursor: pointer;
}
.close-request-btn i {
	color: #fb174e;
	font-size: 18px;
}

.mentor-request-card span h2 {
	font-weight: 400;
	font-size: 20px;
	line-height: 24px;
	color: rgba(46, 53, 58, 0.7);
}
.mentor-request-card span h3 {
	font-weight: 700;
	font-size: 20px;
	line-height: 24px;
	color: #2e353a;
	margin-bottom: 20px;
}
.mentor-request-card span a {
	background: #4544a2;
	border-radius: 5px;
	text-decoration: none;
	padding: 5px 10px;
	color: #fff;
	font-size: 14px;
}
.mentor-request-card span a i {
	color: #fff;
	padding-right: 14px;
}

@media only screen and (max-width: 768px) {
	.AdminMentorRequests {
		padding-left: 25px;
		padding-right: 10px;
	}
}
