.HiringPanelList {
	min-height: calc(100vh - var(--nav-height));
	height: 100%;
	padding-top: 20px;
	padding-left: 30px;
	padding-bottom: 20px;
}

.HiringPanelList h1 {
	font-family: 'Lato', sans-serif;
	font-weight: 700;
	font-size: 24px;
	line-height: 24px;
	color: #2e353a;
	margin-bottom: 25px;
}
