.CommunityBanner {
    height: 100%;
    background: rgba(199, 199, 227, 0.24);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 90px 40px;
    scroll-behavior: smooth;
}

.CommunityBanner-text {
    padding-left: 60px;
    width: 90%;
}
.CommunityBanner-text h1 {
    font-family: 'Lato', sans-serif;
    font-weight: 400;
    font-size: 48px;
    line-height: 72px;
    color: #4544a2;
    margin-bottom: 40px;
}
.CommunityBanner-text h1 span {
    font-weight: 600;
}
.community-btn {
    background: #4544a2;
    color: #fff;
    border-radius: 15px;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 20px 35px;
    font-family: 'Lato', sans-serif;
    font-weight: 600;
    font-size: 17px;
    line-height: 100%;
    margin-left: 10%;
}
.community-btn.mobile-view {
    display: none;
}

.CommunityBanner-illus img {
    height: 400px;
}

@media screen and (max-width: 768px) {
    .CommunityBanner {
        flex-direction: column;
        padding: 50px 0;
    }

    .CommunityBanner-text h1 {
        margin-bottom: 40px;
    }

    .community-btn {
        display: none;
        margin-left: 0;
    }
    .community-btn.mobile-view {
        display: block;
        width: fit-content;
        margin-top: 40px;
    }

    .CommunityBanner-illus {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .CommunityBanner-illus img {
        width: 98%;
    }
}

@media screen and (max-width: 576px) {
    .CommunityBanner-illus img {
        height: 300px;
    }
}
