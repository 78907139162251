.noshow {
    display: none;
}

.show {
    display: block;
}

.chbx {
    width: 20px;
    height: 20px;
}

table {
    width: 100%;
    margin-top: 15px;
    overflow-x: auto;
}

th {
    /* display: flex;
	align-items: center;
	justify-content: space-around; */
    /* word-break: break-all; */
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    color: #2e3b52;
    margin-top: 15px;
    text-align: center;
}

.mainheadd {
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    text-align: right;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #606f89;
}

.mainheadd1 {
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    text-align: left;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #606f89;
    margin-left: -50px;
}

.sno {
    margin-left: -20px;
}

.name {
    margin-left: -150px;
}

.phnumber {
    margin-left: -140px;
}

.email {
    margin-left: -60px;
}

th > td > pre {
    display: flex;
    align-items: center;
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    text-align: right;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #606f89;
}

th > td > div > p {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    color: #606f89;
}

th > td > div > span {
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #2e3b52;
}

td {
    padding: 5px 13px 5px 20px;
    height: 30px;
    /* word-break: break-all; */
    width: fit-content;
    text-align: center;
}

td > div {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.size {
    width: 40px;
}

.banner-btn {
    padding: 10px 15px;
    font-size: 14px;
    font-family: 'Lato', sans-serif;
    font-weight: 600;
    color: #4544a2;
    background-color: #fff;
    border: none;
    outline: none;
    cursor: pointer;
    border-radius: 15px;
    text-align: center;
}

.interview-selected {
    font-family: 'Lato', sans-serif;
    font-weight: 600;
    font-size: 16px;
    color: #fff;
    margin-top: 10px;
}
