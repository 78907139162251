.Bootcamp {
    width: auto;
    height: 100%;
}

/* Hero Section */

.bootcamp-hero {
    height: calc(110vh - var(--nav-height));
    width: 100%;
    padding-right: 20px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

@media only screen and (min-width: 992px) {
    .bootcamp-hero {
        height: calc(100vh - var(--nav-height));
    }
}

.hero-img {
    text-align: center !important;
}

.why-options {
    justify-content: center !important;
    margin-top: 3vw !important;
}

.hero-why-options {
    margin-left: 0.5vw !important;
    margin-right: 0.5vw !important;
}

.hero-why-options h5 {
    width: 25vw;
    font-size: 16px;
}

@media only screen and (max-width: 500px) {
    .hero-why-options h5 {
        width: 25vw;
        font-size: 13px;
    }
}

@media only screen and (min-width: 992px) {
    .why-options {
        justify-content: left !important;
        margin-top: 0vw !important;
    }

    .hero-why-options h5 {
        width: 12vw;
    }
}

.bootcamp-hero-img {
    height: 270px;
    margin: 1vw;
}

@media only screen and (min-width: 992px) {
    .bootcamp-hero-img {
        height: 392px;
    }
}

.hero-content {
    text-align: center;
}

@media only screen and (min-width: 992px) {
    .hero-content {
        text-align: left;
    }
}

.bootcamp-hero h1 {
    font-family: 'Lato', sans-serif;
    font-weight: 400;
    font-size: 5vw;
    line-height: 48px;
    color: #4544a2;
    font-weight: 600;
    margin-bottom: 0.5vw;
}

.bootcamp-hero p {
    margin-bottom: 4.5vw;
    margin-top: 1.5vw;
    margin-left: 5vw;
    margin-right: 5vw;
    font-size: 3.7v;
}

.bootcamp-hero h3 {
    margin-bottom: 1.5vw;
}

@media only screen and (min-width: 992px) {
    .bootcamp-hero h1 {
        font-size: 2.5rem;
    }

    .bootcamp-hero p {
        margin-bottom: 2.1vw;
        margin-left: 0;
        margin-top: 2.5vw;
        font-size: 23px;
    }

    .bootcamp-hero h3 {
        margin-bottom: 1.5vw;
        font-size: 26px;
    }
}

.bootcamp-hero button {
    background-color: #4544a2;
    color: #fff;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 20px 35px;
    border-radius: 15px;
    margin-top: 40px;
    font-family: 'Lato', sans-serif;
    font-weight: 500;
    font-size: 16px;
    line-height: 100%;
}

.bootcamp-details h1 {
    font-size: 5vw;
    text-align: center !important;
    color: #4544a2;
    margin-top: 10vw;
    margin-bottom: 4vw;
}

@media only screen and (min-width: 992px) {
    .bootcamp-details h1 {
        margin-top: 6vw;
        font-size: 2rem;
        color: #4544a2;
        margin-bottom: 0vw !important;
    }
}

.bootcamp-cards {
    margin: 5vh auto !important;
    text-align: center;
}

.bootcamp-cards .title {
    color: #4544a2 !important;
    font-weight: 500;
    margin-top: 1vw;
}

.action-btn {
    justify-content: center;
}

.bootcamp-cards .btn {
    background-color: #4544a2;
    color: #fff;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 10px 25px;
    border-radius: 15px;
    margin-bottom: 15px;
    font-family: 'Lato', sans-serif;
    font-weight: 500;
    font-size: 16px;
    line-height: 100%;
}

.swiper {
    width: 100%;
    height: 100%;
}

.swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;

    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
}

.swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.mySwiper img {
    height: 4vw;
    width: auto;
    padding: 1vw;
}

@media only screen and (min-width: 992px) {
    .mySwiper img {
        height: 3vw;
        width: auto;
    }
}

.mySwiper {
    margin-top: 5vw;
    margin-left: auto;
    margin-right: auto;
}

.benefits img {
    height: 3.3vw;
    width: auto;
    margin-right: 1vw;
}

@media only screen and (min-width: 992px) {
    .benefits img {
        height: 1.2vw;
        width: auto;
        margin-right: 1vw;
    }
}

.benefits-item {
    margin: 1vw;
    padding: 0.5vw;
    text-align: center;
    justify-content: center;
}

.benefits {
    margin-top: -2vw;
    text-align: center;
}

.bootcamp-brands {
    padding: 5vw;
    margin-top: 5vw;
}

.marketing-partner {
    margin-top: 5vw;
    text-align: center;
    vertical-align: middle;
}

.marketing-partner h4 {
    margin-bottom: 2rem;
}

.marketing-partner img {
    height: 4rem;
}

@media only screen and (max-width: 992px) {
    .marketing-partner img {
        height: 2rem;
    }

    .marketing-partner h4 {
        margin-bottom: 1rem;
    }
}
