.AdminResumeReview {
	padding-top: 15px;
	padding-left: 40px;
	height: calc(80vh);
	overflow-y: auto;
}

/* width */
.AdminResumeReview::-webkit-scrollbar {
	width: 6px;
}

/* Track */
.AdminResumeReview::-webkit-scrollbar-track {
	background: #f1f1f1;
}

/* Handle */
.AdminResumeReview::-webkit-scrollbar-thumb {
	background: rgba(0, 0, 0, 0.4);
	border-radius: 15px;
}

/* Handle on hover */
.AdminResumeReview::-webkit-scrollbar-thumb:hover {
	background: #555;
}

.AdminResumeReview h1 {
	font-family: 'Lato', sans-serif;
	font-weight: 700;
	font-size: 20px;
	line-height: 24px;
	color: #2e353a;
	margin-bottom: 30px;
}

.review-card {
	display: flex;
	justify-content: space-between;
	width: fit-content;
	padding: 20px;
	border: 1px solid #d5d7d8;
	border-radius: 15px;
	font-family: 'Lato', sans-serif;
	font-weight: 400;
}
.review-card h2 {
	font-size: 20px;
	line-height: 24px;
	color: #2e353a;
}
.review-card p {
	font-size: 16px;
	line-height: 19px;
	color: rgba(46, 53, 58, 0.7);
}
.review-card button {
	margin-left: 30px;
	background: #4544a2;
	border-radius: 8.5px;
	padding: 15px 20px;
	border: none;
	outline: none;
	color: #fff;
	font-family: 'Lato', sans-serif;
	font-weight: 700;
	font-size: 14px;
	line-height: 100%;
	cursor: pointer;
}

/* Resume Review Page */
.ResumeReview {
	height: calc(90vh);
	width: 100%;
	padding-bottom: 20px;
	overflow-y: auto;
}

.resume-pdf-container {
	height: 100%;
	overflow-y: scroll;
	position: relative;
}

/* width */
.resume-pdf-container::-webkit-scrollbar {
	width: 8px;
}

/* Track */
.resume-pdf-container::-webkit-scrollbar-track {
	background: #f1f1f1;
}

/* Handle */
.resume-pdf-container::-webkit-scrollbar-thumb {
	background: rgba(0, 0, 0, 0.4);
	border-radius: 15px;
}

/* Handle on hover */
.resume-pdf-container::-webkit-scrollbar-thumb:hover {
	background: #555;
}

.resume-pdf-container canvas {
	max-width: 100%;
	height: inherit !important;
	display: block;
}
.resume-pdf {
	display: flex;
	flex-direction: column;
	align-items: center;
}
.page-nav {
	z-index: 50;
	position: absolute;
	top: 0;
	left: 40%;
	transition: all ease-in-out 500ms;
	box-shadow: 0 30px 40px 0 rgb(16 36 94 / 20%);
	border-radius: 4px;
	background-color: #d7def0;
	color: #4544a2;
	display: flex;
	padding: 10px;
	font-weight: 600;
}
.page-nav p {
	margin: 0 10px;
}
.page-nav button {
	cursor: pointer;
	background: none;
	border: none;
	outline: none;
}
.page-nav button i {
	color: #4544a2;
}

.review-text-div {
	padding-top: 55px;
	padding-left: 45px;
	width: 100%;
}
.review-text-div span {
	display: flex;
	justify-content: space-between;
}
.review-text-div span button {
	display: none;
	background: none;
	color: #4544a2;
	box-shadow: none;
}
.review-text-div h1 {
	font-family: 'Lato', sans-serif;
	font-weight: 700;
	font-size: 15px;
	line-height: 18px;
	margin-bottom: 20px;
}
.review-text-div textarea {
	background: rgba(233, 236, 242, 0.4);
	border: 1px solid #e9ecf2;
	box-sizing: border-box;
	border-radius: 12px;
	outline: none;
	padding: 22px 16px;
	font-family: 'Lato', sans-serif;
	font-weight: 500;
	font-size: 15px;
	line-height: 18px;
	color: #4544a2;
	margin-bottom: 45px;
}
.review-text-div textarea::placeholder {
	color: rgba(69, 68, 162, 0.3);
}
.review-text-div button {
	display: block;
	background: #4544a2;
	box-shadow: 0px 4px 5px rgba(69, 68, 162, 0.25);
	border-radius: 12px;
	border: none;
	outline: none;
	cursor: pointer;
	font-family: 'Lato', sans-serif;
	font-weight: 500;
	font-size: 18px;
	line-height: 100%;
	color: #fff;
	padding: 15px 20px;
}

@media only screen and (max-width: 900px) {
	.review-text-div {
		padding-top: 10px;
		padding-left: 20px;
	}
	.review-text-div button {
		display: none;
	}
	.review-text-div span button {
		display: block;
	}
	.review-text-div textarea {
		width: 100%;
		margin-bottom: 30px;
	}
}
