@import url(https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap);
:root {
	--primary: #4544a2;
	--nav-height: 80px;
}

body {
	margin: 0;
	font-family: 'Lato', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	overflow-x: hidden;
}

html {
	overflow-x: hidden;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

a {
	text-decoration: none;
	color: inherit;
}

* {
	margin: 0;
	padding: 0;
}

.required-field {
	font-size: 17px;
	color: red;
	/* padding-left: 3px; */
}

.nav-profile {
    display: flex;
    justify-content: center;
    align-items: center;
}

.nav-avatar {
    height: 50px;
    width: 50px;
    border-radius: 50%;
    margin-right: 10px;
}

.nav-profile span i {
    color: rgba(21, 15, 55, 0.6);
    font-size: 15px;
    cursor: pointer;
    transition: all 400ms ease;
}
.nav-profile span i:hover {
    color: rgb(21, 15, 55);
}
.Events {
	/* min-height: calc(100vh); */
	height: 100%;
	margin: 0 3rem;
	padding-bottom: 20px;
}

.filler-div {
	height: var(--nav-height);
}

/* Filters section */
.Events-filters {
	width: 100%;
	display: flex;
	margin-top: 2.5rem;
	box-sizing: border-box;
}

.related-topics h1 {
	font-family: 'Lato', sans-serif;
	font-weight: 700;
	font-size: 20px;
	margin-bottom: 15px;
}

.related-topics button {
	outline: none;
	border: none;
	padding: 10px 20px;
	margin: 5px;
	border-radius: 7px;
	background-color: #e9ecf2;
	color: rgba(69, 68, 162, 0.3);
	transition: 0.5s;
	font-family: 'Lato', sans-serif;
	font-weight: 400;
	font-size: 16px;
	cursor: pointer;
}

.related-topics .btn-selected {
	background-color: #4544a2;
	color: #fff;
}

.search {
	margin-left: 30px;
}

.search h1 {
	font-family: 'Lato', sans-serif;
	font-weight: 700;
	font-size: 16px;
	color: #4544a2;
	margin-bottom: 12px;
	margin-top: 10px;
}

.search input {
	width: 280px;
	height: 40px;
	border-radius: 7px;
	padding: 0 10px;
	outline: none;
}
.input-icons {
	z-index: 10;
	color: #e9ecf2;
}

.search input:focus {
	outline: none;
}

/* Events Cards */
.Events-cards {
	margin-top: 50px;
}

.event-banner {
	background-position: center;
}

.event-content {
	padding: 20px;
}

.event-content .info {
	display: flex;
	justify-content: space-between;
	margin-bottom: 12px;
}
.event-content .info p:first-child {
	font-weight: 700;
}
.event-content .info p {
	font-family: 'Lato', sans-serif;
	font-weight: 400;
	font-size: 11px;
	color: #6e798c;
}

.event-content h1 {
	margin-bottom: 5px;
	font-family: 'DM Serif Display', sans-serif;
	font-weight: 400;
	font-size: 20px;
	color: #081f32;
	line-height: 25px;
}
.event-content p {
	font-family: 'Lato', sans-serif;
	font-weight: 400;
	font-size: 13px;
	color: #374a59;
	line-height: 19px;
}

.event-actions div {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
}

.event-actions div p {
	display: flex;
	align-items: center;
	font-family: 'Open Sans', sans-serif;
	font-weight: 600;
	font-size: 12px;
	line-height: 15px;
	color: #007ae9;
}
.event-actions div p img {
	margin-right: 10px;
}

.event-actions div a {
	font-family: 'Open Sans', sans-serif;
	font-weight: 600;
	font-size: 12px;
	line-height: 15px;
	color: #007ae9;
}

.event-profile-pic {
	height: 30px;
	width: 30px;
	border-radius: 50%;
}

@media only screen and (max-width: 992px) {
	.Events-filters {
		display: block;
	}
	.search {
		margin-left: 5px;
		margin-top: 10px;
	}
	.search h1 {
		display: none;
	}
}

/* Event Info Page */
.Event-banner {
	height: 368px;
	width: 100%;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}

.Event-banner div {
	padding-top: 70px;
	padding-left: 60px;
	color: #fff;
}
.Event-banner div h1 {
	font-family: 'DM Serif Display', sans-serif;
	font-weight: 400;
	font-size: 40px;
	margin-bottom: 20px;
	line-height: 25px;
}
.Event-banner div p {
	font-family: 'Lato', sans-serif;
	font-weight: 700;
	font-size: 20px;
	line-height: 25px;
}

.Event-banner div ul {
	display: flex;
	list-style: none;
	margin: 20px 0;
	padding-bottom: 40px;
}
.Event-banner div ul li {
	font-family: 'Lato', sans-serif;
	font-weight: 400;
	font-size: 15px;
	line-height: 25px;
	color: rgba(255, 255, 255, 0.7);
	border: 1px solid rgba(255, 255, 255, 0.7);
	padding: 1px 10px;
	border-radius: 20px;
}

.Event-banner div button {
	font-family: 'Lato', sans-serif;
	font-weight: 400;
	font-size: 20px;
	line-height: 25px;
	color: #263238;
	padding: 15px 45px;
	background-color: #fff;
	border: none;
	outline: none;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
	border-radius: 10px;
	cursor: pointer;
}

.Event-info {
	margin-top: 50px;
	margin-left: 60px;
	padding-bottom: 20px;
}

.Event-info h2 {
	font-family: 'Lato', sans-serif;
	font-weight: 400;
	font-size: 25px;
	line-height: 25px;
	padding-bottom: 20px;
}
.Event-info p {
	font-family: 'Lato', sans-serif;
	font-weight: 400;
	font-size: 20px;
	line-height: 25px;
	color: rgba(46, 53, 58, 0.7);
}
.Event-info p:first-child {
	line-height: 30px;
}

@media only screen and (max-width: 992px) {
	.Event-banner div {
		padding-top: 35px;
		padding-left: 25px;
	}
	.Event-banner div h1 {
		line-height: 28px;
		font-size: 22px;
	}
	.Event-banner div p {
		font-size: 16px;
	}
	.Event-banner div button {
		font-size: 15px;
		padding: 13px 40px;
	}
	.Event-info {
		margin-top: 60px;
		margin-left: 30px;
	}
	.Event-info p {
		font-size: 16px;
	}
}

.event-banner-preview img {
	height: 300px;
	width: 300px;
	padding-right: 100px;
	padding-top: 20px;
	border-radius: 20px;
}
.SkillTests {
	min-height: calc(100vh);
	overflow-x: hidden;
}

.filler-div {
	height: var(--nav-height);
}

.SkillTests-banner {
	height: 65px;
	width: 100%;
	background: linear-gradient(109.88deg, #6871e9 0%, #4544a2 73.54%);
	padding-top: 20px;
	padding-left: 31px;
	color: #fff;
}
.SkillTests-banner h1 {
	font-family: 'Lato', sans-serif;
	font-weight: 700;
	font-size: 20px;
	line-height: 100%;
	margin-bottom: 10px;
}
.SkillTests-banner p {
	font-family: 'Lato', sans-serif;
	font-weight: 400;
	font-size: 15px;
	line-height: 100%;
}

@media only screen and (max-width: 768px) {
	.SkillTests-banner {
		padding-left: 23px;
		padding-top: 25px;
		height: 90px;
	}
	.SkillTests-banner h1 {
		font-size: 18px;
	}
	.SkillTests-banner p {
		font-size: 14px;
		line-break: auto;
		width: 280px;
		line-height: 20px;
	}
}

/* SkillTests Content */
.SkillTests-content {
	display: flex;
	position: relative;
}
.SkillTests-sidebar {
	width: 380px;
	background: rgba(233, 236, 242, 0.4);
	height: calc(60vh + 50px);
	padding-left: 30px;
	padding-top: 60px;
	padding-right: 32px;
	display: block;
}

.search-filter {
	width: 100%;
	margin-bottom: 15px;
}
.search-filter div {
	display: flex;
	justify-content: space-between;
}
.search-filter h1 {
	font-family: 'Lato', sans-serif;
	font-weight: 700;
	font-size: 16px;
	line-height: 16px;
	color: #263238;
	margin-bottom: 15px;
}
.search-filter p {
	font-family: 'Lato', sans-serif;
	font-weight: 600;
	font-size: 16px;
	line-height: 16px;
	color: #4544a2;
	cursor: pointer;
}
.search-filter input {
	width: 100%;
	background: #ffffff;
	border: 1px solid #d7def0;
	box-sizing: border-box;
	border-radius: 10px;
	outline: none;
	height: 50px;
	font-family: 'Lato', sans-serif;
	font-weight: 500;
	font-size: 17px;
	line-height: 20px;
	color: rgba(69, 68, 162, 0.3);
	padding: 15px;
}

.roles-filter {
	margin-bottom: 15px;
}
.roles-filter h1 {
	font-family: 'Lato', sans-serif;
	font-weight: 700;
	font-size: 16px;
	line-height: 100%;
	color: #263238;
	margin-bottom: 15px;
}
.roles-select {
	background-color: #fff;
	outline: none;
	border: 1px solid #d7def0;
	box-sizing: border-box;
	border-radius: 10px;
	height: 50px;
}

.levels-filter {
	display: block;
	align-items: center;
}
.levels-filter h1 {
	font-family: 'Lato', sans-serif;
	font-weight: 700;
	font-size: 16px;
	line-height: 16px;
	color: #263238;
	margin-bottom: 15px;
}
.levels-filter label {
	font-family: 'Lato', sans-serif;
	font-weight: 500;
	font-size: 17px;
	line-height: 20px;
	color: #4544a2;
	margin-bottom: 15px;
	margin-left: 15px;
}
.levels-filter span {
	display: block;
	margin-bottom: 15px;
	align-items: center;
}
.levels-filter input {
	accent-color: #4544a2;
}

.filters-btn {
	display: none;
	width: 94px;
	height: 36px;
	font-family: 'Lato', sans-serif;
	font-weight: 600;
	font-size: 16px;
	line-height: 100%;
	color: #4544a2;
	background: #e9ecf2;
	border-radius: 8px;
	border: none;
	outline: none;
	cursor: pointer;
	position: absolute;
	right: 20px;
	top: 10px;
}

@media only screen and (max-width: 992px) {
	body {
		overflow-y: scroll;
	}
	.SkillTests-content {
		display: flex;
		flex-direction: column;
	}
	.SkillTests-sidebar {
		width: 80%;
		background: none;
		height: 100%;
		padding-left: 30px;
		padding-top: 60px;
		padding-right: 32px;
		margin: 0 auto;
		margin-bottom: 40px;
		display: none;
	}
	.filters-btn {
		display: block;
	}
}

/* Skill Test Cards List */
.SkillTests-list {
	width: calc(100vw - 380px);
	height: 60vh;
	overflow-y: scroll;
	padding: 50px;
}

/* width */
.SkillTests-list::-webkit-scrollbar {
	width: 6px;
}

/* Track */
.SkillTests-list::-webkit-scrollbar-track {
	background: #f1f1f1;
}

/* Handle */
.SkillTests-list::-webkit-scrollbar-thumb {
	background: rgba(0, 0, 0, 0.4);
	border-radius: 15px;
}

/* Handle on hover */
.SkillTests-list::-webkit-scrollbar-thumb:hover {
	background: #555;
}

.SkillTest-card {
	height: 240px;
	width: 100%;
	border-radius: 10px;
	display: flex;
	flex-direction: row;
	box-shadow: 2px 0px 21px -1px rgba(0, 0, 0, 0.37);
	-webkit-box-shadow: 2px 0px 21px -1px rgba(0, 0, 0, 0.37);
	-moz-box-shadow: 2px 0px 21px -1px rgba(0, 0, 0, 0.37);
	margin-bottom: 23px;
}
.card-banner img {
	height: 100%;
	border-top-left-radius: 10px;
	border-bottom-left-radius: 10px;
}

.card-content {
	padding-left: 60px;
	padding-top: 20px;
	width: 100%;
	padding-right: 30px;
	position: relative;
}
.card-content p:first-child {
	font-family: 'Open Sans', sans-serif;
	font-weight: 600;
	font-size: 13px;
	line-height: 17px;
	color: #6e798c;
	margin-bottom: 14px;
}
.card-content h1 {
	font-family: 'DM Serif Display', sans-serif;
	font-weight: 400;
	font-size: 25px;
	line-height: 25px;
	color: #081f32;
	margin-bottom: 10px;
}
.card-content p {
	font-family: 'Lato', sans-serif;
	font-weight: 400;
	font-size: 15px;
	line-height: 22px;
	color: rgba(46, 53, 58, 0.7);
	margin-bottom: 18px;
}
.card-content ul {
	display: flex;
	list-style: none;
}
.card-content ul li {
	margin-right: 24px;
	border: 1px solid rgba(69, 68, 162, 0.5);
	border-radius: 15px;
	padding: 2px 10px;
	font-family: 'Open Sans', sans-serif;
	font-weight: 400;
	font-size: 11px;
	line-height: 15px;
	color: rgba(69, 68, 162, 0.5);
}
.card-content a {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	margin-top: 35px;
	font-family: 'Open Sans', sans-serif;
	font-weight: 600;
	font-size: 11px;
	line-height: 15px;
	color: #ff6188;
	position: absolute;
	right: 30px;
	bottom: 25px;
}
.card-content a i {
	padding-left: 2px;
}

.not-found {
	text-align: center;
	margin: auto;
	font-family: 'Open Sans', sans-serif;
	color: #6e798c;
}

@media only screen and (max-width: 992px) {
	.SkillTests-list {
		width: 100%;
		height: -webkit-fit-content;
		height: -moz-fit-content;
		height: fit-content;
		padding: 10px;
		margin-top: 70px;
	}
	.SkillTest-card {
		flex-direction: column;
		height: -webkit-fit-content;
		height: -moz-fit-content;
		height: fit-content;
		width: 400px;
		margin: 0 auto;
		margin-bottom: 20px;
	}
	.card-banner img {
		width: 100%;
		border-top-left-radius: 10px;
		border-top-right-radius: 10px;
		border-bottom-left-radius: 0;
		height: 160px;
	}
	.card-content {
		padding-left: 23px;
		padding-right: 30px;
		position: relative;
		width: -webkit-fit-content;
		width: -moz-fit-content;
		width: fit-content;
	}
	.card-content p:first-child {
		font-size: 11px;
	}
	.card-content h1 {
		font-size: 20px;
	}
	.card-content p {
		font-size: 13px;
		line-break: auto;
		width: 100%;
	}
	.card-content a {
		position: relative;
	}
	.card-content ul li {
		line-height: 15px;
		font-size: 10px;
	}
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
	.SkillTests-list {
		padding: 10px;
	}
	.SkillTest-card {
		flex-direction: row;
		height: 240px;
		width: 800px;
		margin: 0 auto;
		margin-bottom: 20px;
	}
	.card-banner img {
		width: 100%;
		height: 100%;
		border-top-left-radius: 10px;
		border-bottom-left-radius: 10px;
		border-top-right-radius: 0;
	}
}

.SkillTestInfo {
	overflow-x: hidden;
	padding-bottom: 20px;
}

.filler-div {
	height: var(--nav-height);
}

.test-banner {
	height: 240px;
	width: 100%;
	background: linear-gradient(321.69deg, #ff6188 0%, #ffb199 100%);
	color: #fff;
	padding-top: 64px;
	padding-left: 70px;
}
.test-banner h1 {
	font-family: 'DM Serif Display', sans-serif;
	font-weight: 400;
	font-size: 50px;
	line-height: 38px;
	margin-bottom: 11px;
}
.test-banner p {
	font-family: 'Open Sans', sans-serif;
	font-weight: 600;
	font-size: 15px;
	line-height: 20px;
	margin-bottom: 50px;
}
.test-banner a {
	font-family: 'Lato', sans-serif;
	font-weight: 700;
	font-size: 20px;
	line-height: 25px;
	color: #ff9192;
	background: #ffffff;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
	border-radius: 10px;
	padding: 15px 45px;
	border: none;
	outline: none;
	cursor: pointer;
}

@media only screen and (max-width: 768px) {
	.test-banner {
		height: 227px;
		padding-top: 23px;
		padding-left: 37px;
		padding-right: 18px;
	}
	.test-banner h1 {
		font-size: 25px;
		line-break: auto;
		width: 90%;
	}
	.test-banner button {
		font-size: 16px;
		padding: 12px 37px;
	}
}

.test-info {
	padding-top: 50px;
	padding-left: 74px;
	padding-right: 110px;
	border-bottom: 1px solid rgba(38, 50, 56, 0.1);
}
.test-info h2 {
	font-family: 'Lato', sans-serif;
	font-weight: 700;
	font-size: 25px;
	line-height: 30px;
	color: #263238;
	margin-bottom: 20px;
}
.test-info p {
	font-family: 'Lato', sans-serif;
	font-weight: 400;
	font-size: 20px;
	line-height: 34px;
	color: rgba(38, 50, 56, 0.8);
	margin-bottom: 26px;
	width: 95%;
}
.test-info ul {
	display: flex;
	list-style: none;
	margin-bottom: 30px;
}
.test-info ul li {
	font-family: 'Open Sans', sans-serif;
	font-weight: 400;
	font-size: 15px;
	line-height: 20px;
	margin-right: 40px;
	color: rgba(38, 50, 56, 0.8);
	border: 1px solid rgba(38, 50, 56, 0.8);
	border-radius: 15px;
	padding: 2px 10px;
}

@media only screen and (max-width: 768px) {
	.test-info {
		padding-top: 50px;
		padding-left: 23px;
		padding-right: 23px;
	}
	.test-info h2 {
		font-size: 20px;
		margin-bottom: 12px;
	}
	.test-info p {
		font-size: 16px;
	}
	.test-info ul {
		flex-direction: column;
	}
	.test-info ul li {
		font-size: 15px;
		width: -webkit-fit-content;
		width: -moz-fit-content;
		width: fit-content;
		margin-bottom: 15px;
	}
}

.related-info {
	padding-top: 50px;
	padding-left: 74px;
	padding-right: 110px;
}
.related-info h2 {
	margin-bottom: 20px;
}
.course-card {
	display: flex;
	height: 220px;
	width: 760px;
	border: 1px solid #d7def0;
	box-sizing: border-box;
	border-radius: 16px;
	padding-top: 42px;
	padding-left: 25px;
	margin-bottom: 25px;
}
.course-card img {
	height: 140px;
	width: 130px;
	border-radius: 7px;
}
.course-desc {
	padding-left: 24px;
	padding-right: 25px;
	width: 390px;
}
.course-desc h1 {
	font-family: 'DM Serif Display', sans-serif;
	font-weight: 400;
	font-size: 22px;
	line-height: 25px;
	margin-bottom: 8px;
	color: #081f32;
}
.course-desc p {
	font-family: 'Lato', sans-serif;
	font-weight: 400;
	font-size: 16px;
	line-height: 25px;
	color: #6e798c;
}
.course-info p {
	margin-bottom: 18px;
	font-family: 'Open Sans', sans-serif;
	font-weight: 600;
	font-size: 16px;
	line-height: 25px;
	color: #081f32;
}
.course-info p i {
	padding-right: 16px;
	color: #6e798c;
}

.testimonial-card {
	width: 340px;
	height: 240px;
	border: 1px solid #d7def0;
	box-sizing: border-box;
	border-radius: 15px;
	padding-left: 16px;
	padding-top: 20px;
	padding-right: 26px;
	margin-right: 40px;
}
.testimonial-card span {
	display: flex;
	align-items: center;
	margin-bottom: 12px;
}
.testimonial-card span img {
	width: 48px;
	height: 48px;
	border-radius: 5px;
}
.testimonial-card span div {
	padding-left: 12px;
}
.testimonial-card span div h1 {
	font-family: Inter;
	font-weight: 700;
	font-size: 18px;
	line-height: 120%;
}
.testimonial-card span div p {
	font-family: Inter;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 120%;
	color: rgba(60, 60, 67, 0.85);
}
.testimonial-card span i {
	margin-right: 5px;
	color: #d1d1d6;
}
.testimonial-card span i.selected {
	color: #ff9500;
}

@media only screen and (max-width: 768px) {
	.related-info {
		padding-top: 31px;
		padding-left: 23px;
		padding-right: 34px;
	}
	.related-info h2 {
		font-size: 20px;
	}

	.course-card {
		height: 270px;
		width: 306px;
		padding-top: 25px;
		padding-left: 23px;
		padding-right: 26px;
		margin: auto;
		margin-bottom: 25px;
	}
	.course-card img,
	.course-info {
		display: none;
	}
	.course-desc {
		padding-left: 0;
		padding-right: 0;
		width: 250px;
	}
	.course-desc p {
		margin-bottom: 15px;
	}
}

.filler-div {
    height: var(--nav-height);
}

.SkillTest-content {
    display: flex;
}

/* Sidebar */
.question-panel {
    width: 350px;
    height: calc(100vh - var(--nav-height));
    background: #4544a2;
    padding-top: 45px;
    padding-left: 32px;
}
.question-panel h1 {
    font-family: 'Lato', sans-serif;
    font-weight: 700;
    font-size: 15px;
    line-height: 19px;
    color: #fff;
    margin-bottom: 23px;
}
.question-panel button {
    font-family: 'Lato', sans-serif;
    font-weight: 400;
    font-size: 15px;
    line-height: 19px;
    color: #4544a2;
    padding: 15px 20px;
    border-radius: 5px;
    background: #fff;
    border: none;
    outline: none;
    margin-right: 10px;
    margin-bottom: 10px;
    cursor: pointer;
}
.question-panel button.selected {
    background: #07da68;
    color: #fff;
}

.ques-panel-btn {
    display: none;
    font-family: 'Lato', sans-serif;
    font-weight: 500;
    font-size: 16px;
    line-height: 100%;
    color: #fff;
    background: #2e353a;
    border-radius: 14px;
    padding: 7px 15px;
    border: none;
    outline: none;
    cursor: pointer;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-bottom: 17px;
    margin-top: 17px;
    margin-left: 25px;
}

.ques-panel-btn i {
    margin-left: 5px;
}

/* Question Container */
.question-container {
    height: calc(100vh - var(--nav-height));
    width: calc(100% - 350px);
    padding-left: 35px;
    padding-top: 55px;
    padding-right: 110px;
    padding-bottom: 80px;
}

.question-info {
    display: flex;
    justify-content: space-between;
}
.question-info h1 {
    font-family: 'Lato', sans-serif;
    font-weight: 700;
    font-size: 20px;
    line-height: 19px;
    color: #4544a2;
    margin-bottom: 20px;
}

.question-container p {
    font-family: 'Lato', sans-serif;
    font-weight: 700;
    font-size: 20px;
    line-height: 19px;
    color: #4544a2;
    margin-bottom: 16px;
}
.styled-hr {
    border: 0;
    height: 0;
    border-top: 1px solid #e9ecf2;
    border-bottom: 1px solid #e9ecf2;
}

.question p {
    margin: 45px 0;
    color: #2e353a;
}
.question span {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;
}
.question span label {
    padding-left: 20px;
    font-family: 'Lato', sans-serif;
    font-weight: 500;
    font-size: 20px;
    line-height: 19px;
    color: rgba(46, 53, 58, 0.7);
}
.question span input {
    border: 2px solid rgba(46, 53, 58, 0.7);
    width: 30px;
    height: 30px;
    accent-color: #2e353a;
}

.question-nav-btns {
    margin-top: 120px;
    display: flex;
    justify-content: space-between;
    width: 80%;
}
.question-nav-btns button {
    font-family: 'Lato', sans-serif;
    font-weight: 500;
    font-size: 18px;
    line-height: 100%;
    color: #ffffff;
    background: #4544a2;
    border-radius: 12px;
    padding: 19px 36px;
    border: none;
    outline: none;
    cursor: pointer;
}

.dialog-title {
    font-family: 'Lato', sans-serif;
    font-weight: normal;
    font-size: 20px;
    line-height: 113.5%;
}
.dialog-btns button:first-child {
    background: #2e353a;
}
.dialog-btns button {
    font-family: 'Lato', sans-serif;
    font-weight: 500;
    font-size: 16px;
    line-height: 100%;
    background: #4544a2;
    border-radius: 15px;
    padding: 15px 30px;
    border: none;
    outline: none;
    color: #fff;
    cursor: pointer;
}

@media only screen and (max-width: 992px) {
    .question-nav-btns {
        margin-top: 70px;
    }
    body {
        overflow-y: scroll;
    }
}

@media only screen and (max-width: 768px) {
    .SkillTest-content {
        flex-direction: column;
        width: 100vw;
        position: relative;
    }

    .ques-panel-btn {
        display: block;
    }

    .question-panel {
        width: 100%;
        padding-top: 34px;
        padding-left: 25px;
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
    }
    .question-panel h1 {
        display: none;
    }
    .question-panel button {
        margin-right: 20px;
        margin-bottom: 20px;
    }

    .question-container {
        width: 100%;
        padding-left: 23px;
        padding-top: 32px;
        padding-right: 0;
    }

    .question-info h1 {
        font-size: 16px;
    }
    .question-container p {
        font-size: 16px;
    }

    .question-nav-btns button {
        font-size: 17px;
        padding: 15px;
    }
}

.Community {
    width: auto;
    height: 100%;
}

/* Hero Section */
.community-hero {
    height: calc(100vh - var(--nav-height));
    width: 100%;
    /* padding-left: 50px; */
    padding-right: 20px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.hero-banner {
    width: auto;
}
.hero-banner img {
    height: 350px;
}

.hero-text h1 {
    font-family: 'Lato', sans-serif;
    font-weight: 400;
    font-size: 28px;
    line-height: 48px;
    color: #4544a2;
}
.hero-text h1 span {
    font-weight: 600;
}
.hero-text button {
    background-color: #4544a2;
    color: #fff;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 20px 35px;
    border-radius: 15px;
    margin-top: 40px;
    font-family: 'Lato', sans-serif;
    font-weight: 500;
    font-size: 16px;
    line-height: 100%;
}

@media screen and (max-width:500px) {
    .community-events .events-text{
        padding: 1em !important;
    }
}

@media screen and (max-width: 768px) {
    .community-hero {
        flex-direction: column-reverse;
        align-items: flex-start;
        justify-content: start;
        padding-top: 50px;
        padding-left: 40px;
        padding-right: 0;
        height: 100%;
    }
    .hero-banner {
        margin-left: 2rem;
    }
    .hero-banner img {
        height: 250px;
        width: auto;
        margin: 20px auto;
    }
}

@media screen and (max-width: 576px) {
    .community-hero {
        padding-left: 0;
    }
    .hero-text {
        padding-left: 20px;
    }
    .hero-text h1 {
        font-size: 24px;
        line-height: 40px;
    }
    .hero-banner img {
        height: 150px;
    }
}

@media screen and (min-width: 768px) and (max-width: 992px) {
    .community-hero {
        padding-right: 20px;
        padding-left: 20px;
    }
    .hero-banner img {
        height: 200px;
    }
    .hero-text h1 {
        font-size: 25px;
    }
}

/* Features Section */
.community-features {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    border-top: 2px solid rgba(69, 68, 162, 0.3);
    border-bottom: 2px solid rgba(69, 68, 162, 0.3);
    box-sizing: border-box;
    padding: 30px 0;
    background-color: #4544a2;
}

.community-features p {
    font-family: 'Lato', sans-serif;
    font-weight: 400;
    font-size: 18px;
    line-height: 36px;
    color: #4544a2;
    background: #fff;
    border-radius: 10px;
    padding: 13px 23px;
    transition: all 500ms ease;
}
.community-features p i {
    padding-right: 10px;
}
.community-features p:hover {
    box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.11);
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
}

@media screen and (max-width: 768px) {
    .community-features {
        flex-direction: column;
        padding-right: 35px;
        padding-left: 35px;
    }
    .community-features p {
        text-align: center;
        margin-bottom: 16px;
    }
}

@media screen and (min-width: 768px) and (max-width: 992px) {
    .community-features p {
        font-size: 14px;
        font-weight: 600;
        padding: 10px 16px;
    }
}

/* Network Section */
.community-network {
    height: 80vh;
    /* width: 100%; */
    padding-left: 60px;
    padding-right: 60px;
    display: flex;
    justify-content: space-between;
    background: rgba(199, 199, 227, 0.24);
}

.network-text {
    align-self: center;
}
.network-text h1 {
    font-family: 'Lato', sans-serif;
    font-weight: 800;
    font-size: 32px;
    line-height: 48px;
    color: #4544a2;
    margin-bottom: 40px;
}
.network-text p {
    font-family: 'Lato', sans-serif;
    font-weight: 400;
    font-size: 24px;
    line-height: 36px;
    color: #263238;
}

.network-img {
    display: flex;
}

.network-img .row1,
.network-img .row3 {
    height: 100%;
    background: rgba(69, 68, 162, 0.09);
}
.network-img .row1 img,
.network-img .row3 img {
    margin-top: calc(70%);
    border: 4px solid #4544a2;
    border-radius: 20px;
}
.network-img .row2 {
    margin: 0 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}
.network-img .row2 img {
    border: 4px solid #4544a2;
    border-radius: 20px;
}

.network-img.mobile-view {
    display: none;
}
.mobile-view .row1 {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}
.mobile-view .row1 img {
    margin: 20px;
    height: 100px;
    border: 4px solid #4544a2;
    border-radius: 20px;
}

.company-logos {
    margin-top: 50px;
}
.company-logos span {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
}
.company-logos span img {
    height: 80px;
    width: 100px;
    margin-right: 100px;
}
.company-logos span p {
    font-size: 60px;
    font-weight: 600;
    /* padding-bottom: 50px; */
    display: inline-block;
}
/* .company-logos span {
	display: block;
	margin-bottom: 30px;
} */

@media screen and (max-width: 768px) {
    .community-network {
        flex-direction: column;
        height: 100%;
        padding-top: 30px;
        padding-left: 0;
        padding-right: 0;
    }
    .network-text h1 {
        font-size: 28px;
        margin-bottom: 20px;
    }
    .network-text p {
        font-size: 20px;
        line-height: 32px;
    }
    .network-text {
        width: 90vw;
    }
    .network-img {
        display: none;
    }
    .network-img.mobile-view {
        display: flex;
        justify-content: space-between;
        margin-top: 35px;
    }
    .network-img.mobile-view .row1 {
        padding-right: 80px;
    }
    .network-img.mobile-view #row1 {
        padding-left: 80px;
        padding-right: 0;
    }
    .company-logos span {
        margin-left: 20px;
    }
    .company-logos span img {
        height: 70px;
        width: 80px;
        margin-right: 50px;
    }
    .company-logos span p {
        display: none;
    }
}

@media screen and (max-width: 576px) {
    .network-img.mobile-view .row1 {
        padding-right: 0px;
    }
    .network-img.mobile-view #row1 {
        padding-left: 0px;
        padding-right: 0;
    }
    .network-text {
        padding-left: 20px;
    }
}

@media screen and (min-width: 768px) and (max-width: 992px) {
    .community-network {
        padding-left: 20px;
        padding-right: 20px;
    }
    .network-text h1 {
        font-size: 25px;
        margin-bottom: 20px;
    }
    .network-text p {
        font-size: 18px;
        line-height: 30px;
    }
    .network-img .row1 img,
    .network-img .row3 img,
    .network-img .row2 img {
        height: 160px;
    }
}

/* Events Section */
.community-events {
    height: 80vh;
    display: flex;
    justify-content: space-between;
    background-color: #4544a2;
    position: relative;
}
.community-events:before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background-image: url(/static/media/simple_process_bg.edd9bc4b.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    opacity: 0.1;
    z-index: 0;
}

.community-events .events-text {
    padding-top: 60px;
    padding-left: 40px;
    color: #fff;
}
.community-events .events-text h1 {
    font-family: 'Lato', sans-serif;
    font-weight: 400;
    font-size: 48px;
    line-height: 72px;
    margin-bottom: 20px;
}
.community-events .events-text h1 span {
    font-weight: 600;
}
.community-events .events-text p {
    font-family: 'Lato', sans-serif;
    font-weight: 400;
    font-size: 24px;
    line-height: 36px;
}

.community-events .events-banner {
    z-index: 20;
}
.community-events .events-banner img {
    height: 100%;
}

@media screen and (max-width: 768px) {
    .community-events {
        flex-direction: column;
        height: 100%;
    }
    .community-events .events-text {
        padding-top: 40px;
        padding-left: 50px;
    }
    .community-events .events-text h1 {
        font-size: 30px;
        line-height: 40px;
        margin-bottom: 30px;
    }
    .community-events .events-text p {
        font-size: 20px;
        line-height: 30px;
    }
    .community-events .events-banner {
        margin-top: 20px;
    }
    .community-events .events-banner img {
        height: 400px;
        width: 100%;
    }
}

@media screen and (min-width: 992px) and (max-width: 1200px) {
    .community-events .events-text h1 {
        font-size: 35px;
        line-height: 50px;
    }
    .community-events .events-text p {
        font-size: 20px;
    }
    .community-events .events-banner img {
        height: 100%;
        width: 500px;
    }
}

@media screen and (min-width: 768px) and (max-width: 992px) {
    .community-events .events-text h1 {
        font-size: 35px;
        line-height: 50px;
    }
    .community-events .events-text p {
        font-size: 20px;
    }
    .community-events .events-text {
        padding-left: 20px;
        padding-top: 30px;
    }
    .community-events .events-banner img {
        height: 100%;
        width: 400px;
    }
}

/* Interact Section */
.community-interact {
    height: 100%;
    display: flex;
    justify-content: space-between;
    background: rgba(199, 199, 227, 0.24);
    padding-right: 20px;
}

.interact-banner img {
    height: 100%;
}
.interact-banner button {
    display: none;
    background: #4544a2;
    color: #fff;
    border-radius: 15px;
    border: none;
    outline: none;
    cursor: pointer;
    font-family: 'Lato', sans-serif;
    font-weight: 500;
    font-size: 18px;
    line-height: 100%;
    padding: 18px 30px;
    margin: 10px auto;
}

.interact-text {
    padding-top: 120px;
    padding-left: 40px;
}
.interact-text p {
    font-family: 'Lato', sans-serif;
    font-weight: 400;
    font-size: 24px;
    line-height: 36px;
    color: #263238;
    margin-bottom: 45px;
}
.interact-text h1 {
    font-family: 'Lato', sans-serif;
    font-weight: 400;
    font-size: 32px;
    line-height: 48px;
    color: #4544a2;
    margin-bottom: 45px;
}
.interact-text h1 span {
    font-weight: 600;
}
.interact-text button {
    background: #4544a2;
    color: #fff;
    border-radius: 15px;
    border: none;
    outline: none;
    cursor: pointer;
    font-family: 'Lato', sans-serif;
    font-weight: 500;
    font-size: 18px;
    line-height: 100%;
    padding: 18px 30px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: 0 auto;
    margin-left: 30%;
}

@media screen and (max-width: 768px) {
    .community-interact {
        height: 100%;
        flex-direction: column-reverse;
        padding-bottom: 40px;
        background-color: #eeeef7;
        justify-content: center;
        align-items: center;
    }
    .interact-text {
        padding-top: 50px;
        padding-left: 0;
    }
    .interact-text h1 {
        margin-bottom: 0;
        text-align: center;
    }
    .interact-text p {
        display: none;
    }
    .interact-text button {
        display: none;
    }
    .interact-banner img {
        margin: 0 auto;
        /* margin-left: 50px; */
        width: 500px;
    }
    .interact-banner button {
        display: block;
    }
}

@media screen and (max-width: 576px) {
    .community-interact {
        justify-content: center;
        align-items: center;
    }
    .interact-banner img {
        width: 350px;
        height: 100%;
        margin-left: 0;
    }
}

@media screen and (min-width: 992px) and (max-width: 1200px) {
    .interact-text {
        padding-top: 40px;
    }
    .interact-text p {
        margin-bottom: 40px;
        font-size: 22px;
        line-height: 30px;
    }
    .interact-text h1 {
        margin-bottom: 20px;
        font-size: 28px;
    }
    .interact-text button {
        font-size: 16px;
        padding: 16px 25px;
        border-radius: 18px;
        margin-left: 20%;
    }
}

@media screen and (min-width: 768px) and (max-width: 992px) {
    .interact-banner img {
        width: 450px;
        height: 100%;
    }
    .interact-text {
        padding-top: 40px;
        padding-left: 40px;
    }
    .interact-text p {
        margin-bottom: 40px;
        font-size: 22px;
        line-height: 30px;
    }
    .interact-text h1 {
        margin-bottom: 20px;
        font-size: 28px;
    }
    .interact-text button {
        font-size: 16px;
        padding: 16px 25px;
        border-radius: 18px;
    }
}

.DashboardFooter {
    height: 100%;
    /* background-color: #252526; */
    display: block;
    /* text-align: center; */
    /* color: #fff; */
    /* padding: 20px;
	padding-left: 60px; */
    font-family: 'Roboto', sans-serif;
    margin-top: 20px;
}
.DashboardFooter h1 {
    margin-bottom: 40px;
    font-size: 32px;
    font-weight: 800;
    color: #263238;
    position: relative;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding-left: 60px;
}
.DashboardFooter h1::after {
    content: '';
    width: 30%;
    height: 8px;
    border-radius: 10px;
    background-color: var(--primary);
    position: absolute;
    bottom: -10px;
    right: -20px;
}
.things-todo {
    width: 100%;
    padding: 20px;
}
.dashboard-footer-div {
    /* color: #fff; */
    width: 210px;
    margin: auto;
    border-radius: 15px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    transition: all 500ms ease;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
    padding-top: 20px;
}
.dashboard-footer-div:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
}
.dashboard-footer-div i {
    color: #4544a2;
    font-size: 28px;
    margin-bottom: 15px;
    height: 40px;
}
.dashboard-footer-div img {
    height: 30px;
    width: 30px;
    fill: #000;
    margin-bottom: 15px;
}
.dashboard-footer-div h2 {
    margin-bottom: 10px;
    font-size: 15px;
    height: 30px;
}
.dashboard-footer-div p {
    width: 95%;
    text-align: center;
    font-size: 14px;
    line-height: 25px;
    line-break: loose;
    color: #263238d9;
    height: 150px;
}

.footer-social-media-text {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    border-top: 2px solid #d7def0;
    padding: 15px 0;
    margin-top: 20px;
}
.footer-social-media-text a {
    margin: 0 15px;
}
.footer-social-media-text a i {
    color: #fff;
}
.whatsapp-btn {
    background-color: #25d366;
    padding: 6px 10px;
    border-radius: 6px;
}
.discord-btn {
    background-color: #5865f2;
    padding: 6px 8px;
    border-radius: 6px;
}

@media screen and (max-width: 768px) {
    .dashboard-footer-div {
        width: 250px;
        padding-bottom: 20px;
        padding-top: 20px;
    }
    .dashboard-footer-div p {
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
    }
    .DashboardFooter h1 {
        font-size: 25px;
    }
}

.CommunityBanner {
    height: 100%;
    background: rgba(199, 199, 227, 0.24);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 90px 40px;
    scroll-behavior: smooth;
}

.CommunityBanner-text {
    padding-left: 60px;
    width: 90%;
}
.CommunityBanner-text h1 {
    font-family: 'Lato', sans-serif;
    font-weight: 400;
    font-size: 48px;
    line-height: 72px;
    color: #4544a2;
    margin-bottom: 40px;
}
.CommunityBanner-text h1 span {
    font-weight: 600;
}
.community-btn {
    background: #4544a2;
    color: #fff;
    border-radius: 15px;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 20px 35px;
    font-family: 'Lato', sans-serif;
    font-weight: 600;
    font-size: 17px;
    line-height: 100%;
    margin-left: 10%;
}
.community-btn.mobile-view {
    display: none;
}

.CommunityBanner-illus img {
    height: 400px;
}

@media screen and (max-width: 768px) {
    .CommunityBanner {
        flex-direction: column;
        padding: 50px 0;
    }

    .CommunityBanner-text h1 {
        margin-bottom: 40px;
    }

    .community-btn {
        display: none;
        margin-left: 0;
    }
    .community-btn.mobile-view {
        display: block;
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
        margin-top: 40px;
    }

    .CommunityBanner-illus {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .CommunityBanner-illus img {
        width: 98%;
    }
}

@media screen and (max-width: 576px) {
    .CommunityBanner-illus img {
        height: 300px;
    }
}

.noshow{
    display: none;
}

.show{
    display: block;
}

hr{
    width: 30vw;
    height: 1px;
    margin-top:20px;
    background-color: #4544A2;
    /* margin-left:-80px; */
}


.unit { 
    position: absolute; 
    display: block; 
    left: 5px; 
    top: 10px; 
    z-index: 9; 
  }

.options{
    padding: 20px 5px;
    border: 1px solid black;
}  

.CandidateDashboardProfile {
    padding-top: 40px;
    padding-left: 50px;
    width: auto;
}

.CandidateDashboardProfile .header {
    font-family: 'Lato', sans-serif;
    font-weight: 700;
    font-size: 25px;
    line-height: 24px;
    color: #2E353A;
    text-align: center;
}

.profile-container {
    width: 100%;
    margin: 0 auto;
}

.profile-container .avatar-container {
    position: relative;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px dashed #d7def0;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: auto;
    margin-top: 20px;
    padding: 20px;
    border-radius: 15px;
}
.profile-container .avatar-container img {
    height: 60px;
    width: 60px;
    border-radius: 50%;
}
.profile-container .avatar-container span {
    display: flex;
    flex-direction: column;
    margin-left: 15px;
}
.profile-container .avatar-container span input {
    margin-bottom: 10px;
}
.profile-container .avatar-container span button {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    cursor: pointer;
    padding: 10px 15px;
    background: #4544A2;
    border-radius: 10px;
    font-family: Lato;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 100%;
    color: #FFFFFF;
    border: none;
    outline: none;
}

.avatar-container h1 {
    font-family: 'Lato', sans-serif;
    font-weight: 600;
    font-size: 15px;
    color: #2E353A;
    margin-right: 20px;
}

.field-container {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: 30px auto;
    border: 1px solid #d7def0;
    border-radius: 15px;
    padding: 15px 30px;
    box-shadow: 1px 0px 37px 6px rgba(0,0,0,0.25);
    -webkit-box-shadow: 1px 0px 37px 6px rgba(0,0,0,0.25);
    -moz-box-shadow: 1px 0px 37px 6px rgba(0,0,0,0.25);
}

.field-container div h2 {
    font-family: 'Lato', sans-serif;
    font-weight: 500;
    font-size: 20px;
    line-height: 20px;
    margin-bottom: 5px;
    margin-top: 5px;
    color: #2E353A;
}

.input-container {
    display: flex;
}

.input-container span {
    margin: 10px;
}

.input-container span label {
    display: block;
    margin-bottom: 5px;
    font-family: 'Lato', sans-serif;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #4544A2;
}

.input-container span h3 {
    font-family: 'Lato', sans-serif;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 4px;
}

.field-container div input,
.field-container div select {
    display: block;
    background: rgba(233, 236, 242, 0.4);
    border: 1px solid #E9ECF2;
    box-sizing: border-box;
    border-radius: 12px;
    padding: 10px 15px;
}

.profile-select-input {
    background: rgba(233, 236, 242, 0.4);
    border: 1px solid #E9ECF2;
    box-sizing: border-box;
    border-radius: 12px;
    padding: 10px 15px;
    cursor: pointer;
}

.add-btn {
    background-color: #4544A2;
    color: #fff;
    font-family: 'Lato', sans-serif;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 4px;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 5px 10px;
    border-radius: 10px;
}

.save-btn {
    background-color: #25C077;
    color: #fff;
    font-family: 'Lato', sans-serif;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 4px;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 10px;
    border-radius: 10px;
}
.HiringPanelList {
	min-height: calc(100vh - var(--nav-height));
	height: 100%;
	padding-top: 20px;
	padding-left: 30px;
	padding-bottom: 20px;
}

.HiringPanelList h1 {
	font-family: 'Lato', sans-serif;
	font-weight: 700;
	font-size: 24px;
	line-height: 24px;
	color: #2e353a;
	margin-bottom: 25px;
}

.noshow {
    display: none;
}

.show {
    display: block;
}

.haze {
    opacity: 0.5;
}

.nohaze {
    opacity: 1;
}

.chbx {
    width: 20px;
    height: 20px;
}

table {
    width: 100%;
    overflow-x: auto;
}

th {
    /* display: flex;
    align-items: center;
    justify-content: space-around; */
    /* word-break: break-all; */
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    color: #2e3b52;
    margin-top: 15px;
    text-align: center;
}

.mainheadd {
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    text-align: right;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #606f89;
}

th > td > pre {
    display: flex;
    align-items: center;
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    text-align: right;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #606f89;
}

th > td > div > p {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    color: #606f89;
}

th > td > div > span {
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #2e3b52;
}

td {
    padding: 5px 13px 5px 20px;
    height: 30px;
    /* word-break: break-all; */
    text-align: center;
}

td > div {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.size {
    width: 40px;
}

/* Interview selection modal */
.interview-modal {
    border: none;
    width: 100%;
    text-align: center;
}
.interview-modal h1 {
    font-family: 'DM Serif Display', sans-serif;
    font-weight: 400;
    font-size: 30px;
    line-height: 111%;
    color: #4544a2;
    text-align: center;
    margin-bottom: 10px;
}
.interview-modal p {
    font-family: 'Lato', sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 111%;
    color: rgba(69, 68, 162, 0.5);
    text-align: center;
    margin-bottom: 10px;
}
.interview-modal input,
.interview-modal select {
    background: rgba(233, 236, 242, 0.4);
    border: 1px solid #e9ecf2;
    box-sizing: border-box;
    border-radius: 12px;
    outline: none;
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 111%;
    color: rgba(69, 68, 162, 0.5);
    display: block;
    padding: 20px 25px;
    margin: 0 auto;
    margin-bottom: 10px;
    width: 300px;
}
.interview-modal button {
    background: #4544a2;
    border-radius: 8px;
    font-family: 'Lato', sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 111%;
    text-align: center;
    color: #ffffff;
    padding: 15px 20px;
    border: none;
    outline: none;
    cursor: pointer;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: 0 auto;
}

.link {
    /* color: #4544A2; */
    text-decoration: underline;
    margin-left: 5px;
}

.show{
    display: block;
}

.noshow{
    display: none;
}

.smiley{
    z-index: 11;
}

.thumb{
    z-index: 10;
}

.heart{
    z-index: 9;
}

.AdminDashboard {
	width: 100%;
	height: calc(82vh);
	overflow-y: auto;
}

/* width */
.AdminDashboard::-webkit-scrollbar {
	width: 6px;
}

/* Track */
.AdminDashboard::-webkit-scrollbar-track {
	background: #f1f1f1;
}

/* Handle */
.AdminDashboard::-webkit-scrollbar-thumb {
	background: rgba(0, 0, 0, 0.4);
	border-radius: 15px;
}

/* Handle on hover */
.AdminDashboard::-webkit-scrollbar-thumb:hover {
	background: #555;
}

.admin-dash-content {
	padding-left: 40px;
	padding-top: 12px;
	padding-right: 55px;
}
.admin-dash-content h2 {
	font-family: 'Lato', sans-serif;
	font-weight: 700;
	font-size: 20px;
	line-height: 24px;
	color: #2e353a;
	margin-bottom: 20px;
}
.admin-dash-content h2 i {
	padding-left: 30px;
	font-size: 16px;
	cursor: pointer;
}

.weekly-stats {
	width: 100%;
	height: 116px;
	border: 1px solid #e6e7e9;
	box-sizing: border-box;
	border-radius: 15px;
	margin-bottom: 16px;
	display: flex;
	justify-content: space-evenly;
	padding-top: 20px;
}
.weekly-stats span {
	text-align: center;
}
.weekly-stats span h1 {
	font-family: 'Lato', sans-serif;
	font-weight: 400;
	font-size: 20px;
	line-height: 24px;
	color: rgba(46, 53, 58, 0.8);
	margin-bottom: 5px;
}
.weekly-stats span p {
	font-family: 'Lato', sans-serif;
	font-weight: 800;
	font-size: 40px;
	line-height: 48px;
	color: #6871e9;
}

.new-jobs {
	width: 70%;
	float: left;
}
.new-jobs span {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.new-jobs span h3 {
	font-family: 'Lato', sans-serif;
	font-weight: bold;
	font-size: 15px;
	line-height: 100%;
	color: #4544a2;
	cursor: pointer;
}

.new-jobs-card {
	border: 1px solid #e6e7e9;
	box-sizing: border-box;
	border-radius: 15px;
	padding-top: 26px;
	padding-left: 20px;
	padding-bottom: 14px;
	padding-right: 25px;
	color: #2e353a;
}
.new-jobs-card h3 {
	font-family: 'DM Serif Display', sans-serif;
	font-weight: 400;
	font-size: 20px;
	line-height: 100%;
	margin-bottom: 10px;
}
.new-jobs-card p {
	font-family: 'Lato', sans-serif;
	font-weight: 400;
	font-size: 15px;
	line-height: 100%;
}
.new-jobs-card p:last-child {
	text-align: end;
	color: rgba(38, 50, 56, 0.5);
}

.create-btns {
	width: 25%;
	float: right;
}
.create-btns div {
	width: 100%;
	border: 1px solid #e6e7e9;
	box-sizing: border-box;
	border-radius: 15px;
	padding: 30px 18px;
	margin-bottom: 15px;
	padding-bottom: 15px;
}
.create-btns div h1 {
	font-family: 'DM Serif Display', sans-serif;
	font-weight: 400;
	font-size: 25px;
	line-height: 100%;
	color: #2e353a;
	margin-bottom: 8px;
	text-align: center;
}
.create-btns div p {
	font-family: 'Lato', sans-serif;
	font-weight: 400;
	font-size: 14px;
	line-height: 100%;
	color: rgba(38, 50, 56, 0.5);
	margin-bottom: 25px;
	text-align: center;
}
.create-btns div button {
	background: #4544a2;
	color: #fff;
	border-radius: 8px;
	font-family: 'Lato', sans-serif;
	font-weight: 400;
	font-size: 12px;
	line-height: 100%;
	border: none;
	outline: none;
	cursor: pointer;
	padding: 10px 46px;
	margin-left: 35px;
}

.admin-dash-not-found {
	width: 100%;
	height: 100%;
	text-align: center;
	margin-top: 8rem;
	font-family: 'Lato', sans-serif;
	font-weight: 400;
	font-size: 14px;
	color: #2e353a;
}

@media only screen and (max-width: 768px) {
	.AdminDashboard {
		padding-bottom: 20px;
	}
	.dashboard-main {
		display: flex;
		flex-direction: column-reverse;
	}

	.weekly-stats {
		width: 100%;
		height: -webkit-fit-content;
		height: -moz-fit-content;
		height: fit-content;
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		grid-template-rows: repeat(2, 1fr);
		grid-column-gap: 10px;
		grid-row-gap: 0px;
		border: none;
		padding-top: 0;
	}
	#events-attended {
		display: none;
	}
	.weekly-stats span {
		width: 100%;
		border: 1px solid #d7def0;
		box-sizing: border-box;
		border-radius: 10px;
		margin-right: 10px;
		margin-bottom: 10px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 10px;
		text-align: start;
	}

	.new-jobs {
		float: none;
		width: 100%;
	}
	.create-btns {
		float: none;
		width: 100%;
		margin-bottom: 16px;
	}
	.create-btns div {
		display: flex;
		flex-direction: column;
		/* justify-content: center; */
		align-items: center;
	}
	.create-btns div button {
		margin-left: 0;
		width: 100px;
		padding: 10px;
		font-size: 16px;
	}
}

/* Create Event Modal */
.event-modal-content {
	width: 100%;
}

.event-modal-content h1 {
	text-align: center;
	margin-bottom: 25px;
	font-family: 'DM Serif Display', sans-serif;
	font-weight: 400;
	font-size: 24px;
	line-height: 100%;
	color: #2e353a;
}

.banner-upload {
	width: -webkit-fit-content;
	width: -moz-fit-content;
	width: fit-content;
	margin: 0 auto;
	margin-bottom: 20px;
	padding: 26px 90px;
	border: 2px dashed #d7def0;
	box-sizing: border-box;
	border-radius: 12px;
	font-family: 'Lato', sans-serif;
	font-weight: 500;
	text-align: center;
}
.banner-upload h2 {
	font-size: 17px;
	line-height: 20px;
	color: #2e353a;
	margin-bottom: 6px;
}
.banner-upload input {
	display: block;
	width: auto;
	margin: 15px auto;
	padding-left: 40px;
}
.banner-upload button {
	border: none;
	outline: none;
	background-color: #4544a2;
	color: #fff;
	border-radius: 15px;
	padding: 10px 15px;
	cursor: pointer;
}
.banner-upload button:disabled {
	opacity: 0.5;
	cursor: not-allowed;
}

.event-form {
	width: 100%;
}
.event-form label {
	display: block;
	margin-bottom: 10px;
	font-family: 'Lato', sans-serif;
	font-weight: 500;
	font-size: 15px;
	line-height: 20px;
	color: #263238;
}

.event-form input,
.event-form textarea,
.event-form select {
	width: 250px;
	background: rgba(233, 236, 242, 0.4);
	border: 1px solid #e9ecf2;
	box-sizing: border-box;
	border-radius: 12px;
	outline: none;
	font-family: 'Lato', sans-serif;
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
	color: rgba(69, 68, 162, 0.6);
	padding: 15px 16px;
	margin-bottom: 10px;
}

.event-form input::-webkit-input-placeholder, .event-form textarea::-webkit-input-placeholder {
	font-family: 'Lato', sans-serif;
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
	color: rgba(69, 68, 162, 0.6);
}

.event-form input:-ms-input-placeholder, .event-form textarea:-ms-input-placeholder {
	font-family: 'Lato', sans-serif;
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
	color: rgba(69, 68, 162, 0.6);
}

.event-form input::placeholder,
.event-form textarea::placeholder {
	font-family: 'Lato', sans-serif;
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
	color: rgba(69, 68, 162, 0.6);
}

.event-form input[type='date'],
.event-form input[type='time'],
.event-form select {
	cursor: pointer;
}

.event-form button {
	background: #4544a2;
	color: #fff;
	border-radius: 12px;
	font-family: 'Lato', sans-serif;
	font-weight: 500;
	font-size: 17px;
	line-height: 100%;
	padding: 15px 16px;
	border: none;
	outline: none;
	cursor: pointer;
	margin-top: 10px;
}

.event-tags-display {
	padding-left: 20px;
	margin-bottom: 5px;
}
.event-tags-display span {
	background-color: #6871e9;
	color: rgba(255, 255, 255, 0.5);
	font-family: 'Lato', sans-serif;
	font-weight: 600;
	font-size: 13px;
	padding: 6px;
	border-radius: 7px;
	margin-right: 5px;
}
.event-tags-display span i {
	padding-left: 5px;
	color: #fff;
	cursor: pointer;
}

/* Create Forum Modal */
.forum-modal-content {
	width: 100%;
}

.forum-modal-content h1 {
	text-align: center;
	margin-bottom: 25px;
	font-family: 'DM Serif Display', sans-serif;
	font-weight: 400;
	font-size: 24px;
	line-height: 100%;
	color: #2e353a;
}

.forum-form {
	width: 100%;
}

.forum-form label {
	display: block;
	margin-bottom: 10px;
	font-family: 'Lato', sans-serif;
	font-weight: 500;
	font-size: 15px;
	line-height: 20px;
	color: #263238;
}

.forum-form input,
.forum-form textarea,
.forum-form select {
	width: 250px;
	background: rgba(233, 236, 242, 0.4);
	border: 1px solid #e9ecf2;
	box-sizing: border-box;
	border-radius: 12px;
	outline: none;
	font-family: 'Lato', sans-serif;
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
	color: rgba(69, 68, 162, 0.6);
	padding: 15px 16px;
	margin-bottom: 10px;
}

.forum-form input::-webkit-input-placeholder, .forum-form textarea::-webkit-input-placeholder {
	font-family: 'Lato', sans-serif;
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
	color: rgba(69, 68, 162, 0.6);
}

.forum-form input:-ms-input-placeholder, .forum-form textarea:-ms-input-placeholder {
	font-family: 'Lato', sans-serif;
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
	color: rgba(69, 68, 162, 0.6);
}

.forum-form input::placeholder,
.forum-form textarea::placeholder {
	font-family: 'Lato', sans-serif;
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
	color: rgba(69, 68, 162, 0.6);
}

.forum-form input[type='date'],
.forum-form input[type='time'],
.forum-form select {
	cursor: pointer;
}

.forum-form button {
	background: #4544a2;
	color: #fff;
	border-radius: 12px;
	font-family: 'Lato', sans-serif;
	font-weight: 500;
	font-size: 17px;
	line-height: 100%;
	padding: 15px 16px;
	border: none;
	outline: none;
	cursor: pointer;
	margin-top: 10px;
}

.forum-tags-display {
	padding-left: 20px;
	margin-bottom: 5px;
}
.forum-tags-display span {
	background-color: #6871e9;
	color: rgba(255, 255, 255, 0.5);
	font-family: 'Lato', sans-serif;
	font-weight: 600;
	font-size: 13px;
	padding: 6px;
	border-radius: 7px;
	margin-right: 5px;
}
.forum-tags-display span i {
	padding-left: 5px;
	color: #fff;
	cursor: pointer;
}

.details-modal-header {
	font-family: 'DM Serif Display';
	font-weight: 400;
	font-size: 30px;
	line-height: 111%;
	color: #fff;
	text-align: center;
	margin-bottom: 20px;
}

.details-modal-contents {
	padding: 0 20px;
}

.details-modal-contents div {
	margin-bottom: 15px;
}

.details-modal-contents div h2 {
	font-family: 'Lato';
	font-weight: 600;
	font-size: 21px;
	line-height: 111%;
	color: #fff;
	margin-bottom: 6px;
}

.details-modal-contents div p {
	font-family: 'Lato';
	font-weight: 400;
	font-size: 16px;
	line-height: 111%;
	color: #fff;
	margin-left: 10px;
	white-space: pre-wrap;
}

.details-modal-contents div ul {
	list-style: none;
}

.details-modal-contents div ul li {
	margin-left: 10px;
	margin-bottom: 4px;
	font-family: 'Lato';
	font-weight: 400;
	font-size: 16px;
	line-height: 111%;
	color: #fff;
}

.styled-hr-2 {
	border: 0;
	height: 1px;
	background-image: linear-gradient(
		to right,
		rgba(255, 255, 255, 0),
		rgba(255, 255, 255, 0.75),
		rgba(255, 255, 255, 0)
	);
}

.noshow {
    display: none;
}

.show {
    display: block;
}

.chbx {
    width: 20px;
    height: 20px;
}

table {
    width: 100%;
    margin-top: 15px;
    overflow-x: auto;
}

th {
    /* display: flex;
	align-items: center;
	justify-content: space-around; */
    /* word-break: break-all; */
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    color: #2e3b52;
    margin-top: 15px;
    text-align: center;
}

.mainheadd {
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    text-align: right;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #606f89;
}

.mainheadd1 {
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    text-align: left;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #606f89;
    margin-left: -50px;
}

.sno {
    margin-left: -20px;
}

.name {
    margin-left: -150px;
}

.phnumber {
    margin-left: -140px;
}

.email {
    margin-left: -60px;
}

th > td > pre {
    display: flex;
    align-items: center;
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    text-align: right;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #606f89;
}

th > td > div > p {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    color: #606f89;
}

th > td > div > span {
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #2e3b52;
}

td {
    padding: 5px 13px 5px 20px;
    height: 30px;
    /* word-break: break-all; */
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    text-align: center;
}

td > div {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.size {
    width: 40px;
}

.banner-btn {
    padding: 10px 15px;
    font-size: 14px;
    font-family: 'Lato', sans-serif;
    font-weight: 600;
    color: #4544a2;
    background-color: #fff;
    border: none;
    outline: none;
    cursor: pointer;
    border-radius: 15px;
    text-align: center;
}

.interview-selected {
    font-family: 'Lato', sans-serif;
    font-weight: 600;
    font-size: 16px;
    color: #fff;
    margin-top: 10px;
}

.AdminManageJobs {
    height: calc(80vh);
    width: auto;
    padding-top: 34px;
    padding-left: 32px;
    overflow-y: auto;
}

/* width */
.AdminManageJobs::-webkit-scrollbar {
    width: 6px;
}

/* Track */
.AdminManageJobs::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
.AdminManageJobs::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.4);
    border-radius: 15px;
}

/* Handle on hover */
.AdminManageJobs::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.new-jobs-container h1,
.ongoing-jobs-container h1,
.archived-job-container h1 {
    font-family: 'Lato', sans-serif;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    color: #2e353a;
    margin-bottom: 25px;
}

.new-jobs-container {
    margin-bottom: 45px;
}

.admin-job-card {
    border: 1px solid #d7def0;
    box-sizing: border-box;
    border-radius: 15px;
    padding: 25px 15px;
    cursor: pointer;
}
.admin-job-card h1 {
    font-family: 'DM Serif Display', sans-serif;
    font-weight: 400;
    font-size: 20px;
    line-height: 100%;
    color: rgba(46, 53, 58, 0.8);
    margin-bottom: 10px;
}
.admin-job-card h2 {
    font-family: 'Lato', sans-serif;
    font-weight: 400;
    font-size: 15px;
    line-height: 100%;
    color: rgba(46, 53, 58, 0.8);
    margin-bottom: 5px;
}
.admin-job-card p {
    text-align: end;
    font-family: 'Lato', sans-serif;
    font-weight: 400;
    font-size: 15px;
    line-height: 100%;
    color: rgba(38, 50, 56, 0.5);
}

/* Job Details */
.AdminJobDetails {
    height: calc(84vh);
    width: auto;
    overflow-y: auto;
    overflow-x: hidden;
}

/* width */
.AdminJobDetails::-webkit-scrollbar {
    width: 6px;
}

/* Track */
.AdminJobDetails::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
.AdminJobDetails::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.4);
    border-radius: 15px;
}

/* Handle on hover */
.AdminJobDetails::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.createblogs {
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 90px 10px;
}

.AdminSkillTests {
	/* min-width: 90%; */
	width: auto;
	height: calc(80vh);
	padding-left: 20px;
	padding-top: 33px;
	padding-bottom: 50px;
	padding-right: 10px;
	overflow-y: scroll;
}

/* width */
.AdminSkillTests::-webkit-scrollbar {
	width: 6px;
}

/* Track */
.AdminSkillTests::-webkit-scrollbar-track {
	background: #f1f1f1;
}

/* Handle */
.AdminSkillTests::-webkit-scrollbar-thumb {
	background: rgba(0, 0, 0, 0.4);
	border-radius: 15px;
}

/* Handle on hover */
.AdminSkillTests::-webkit-scrollbar-thumb:hover {
	background: #555;
}

.AdminSkillTests .SkillTest-card {
	min-width: 100%;
	width: -webkit-fit-content;
	width: -moz-fit-content;
	width: fit-content;
}

.card-content {
	padding-left: 20px;
	padding-right: 10px;
}

.card-content a {
	color: #4544a2;
}

.admin-tests-header {
	margin-bottom: 30px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding-right: 10px;
	padding-left: 10px;
}
.admin-tests-header h1 {
	font-family: 'Lato', sans-serif;
	font-weight: 700;
	font-size: 20px;
	line-height: 24px;
	color: #2e353a;
}
.admin-tests-header button {
	border: 1px solid #4544a2;
	box-sizing: border-box;
	border-radius: 40px;
	background: none;
	font-family: 'Lato', sans-serif;
	font-weight: 400;
	font-size: 14px;
	line-height: 17px;
	color: #4544a2;
	padding: 10px 20px;
	cursor: pointer;
}

/* Create Skill Test Form */
.SkillTestForm {
	padding: 0;
	padding-right: 50px;
	width: 100%;
}

.SkillTestForm h1 {
	font-family: 'Lato', sans-serif;
	font-weight: 700;
	font-size: 20px;
	line-height: 24px;
	color: #2e353a;
	margin-bottom: 20px;
}

.create-test-form {
	width: 100%;
	position: relative;
}

.input-label {
	display: block;
	margin-bottom: 10px;
	font-family: 'Lato', sans-serif;
	font-weight: 500;
	font-size: 16px;
	line-height: 20px;
	color: #000;
}

.test-name-input,
.question-text,
.create-test-form select,
.create-test-form textarea {
	background: rgba(233, 236, 242, 0.4);
	border: 1px solid #e9ecf2;
	box-sizing: border-box;
	border-radius: 12px;
	outline: none;
	padding: 16px 16px;
	font-family: 'Lato', sans-serif;
	font-weight: 500;
	font-size: 16px;
	line-height: 20px;
	color: #4544a2;
	width: 300px;
}

.create-test-form input::-webkit-input-placeholder, .create-test-form textarea::-webkit-input-placeholder {
	color: rgba(69, 68, 162, 0.3);
}

.create-test-form input:-ms-input-placeholder, .create-test-form textarea:-ms-input-placeholder {
	color: rgba(69, 68, 162, 0.3);
}

.create-test-form input::placeholder,
.create-test-form textarea::placeholder {
	color: rgba(69, 68, 162, 0.3);
}

.create-test-form select {
	color: rgba(69, 68, 162, 0.6);
}

.level-btns span label {
	padding-left: 10px;
	padding-right: 40px;
	color: rgba(38, 50, 56, 0.5);
	font-size: 17px;
}

.level-btns span input {
	accent-color: #4544a2;
}

.job-types-display {
	margin-top: 10px;
}
.job-types-display span {
	background: #6871e9;
	border-radius: 7px;
	font-family: 'Lato', sans-serif;
	font-weight: 600;
	font-size: 13px;
	line-height: 21px;
	color: rgba(255, 255, 255, 0.5);
	padding: 7px 14px;
	margin-right: 5px;
}
.job-types-display span i {
	cursor: pointer;
	color: #fff;
	padding-left: 15px;
}

.add-questions {
	border-top: 1px solid #d7def0;
	margin-top: 20px;
	padding-top: 20px;
}

.question-div {
	display: flex;
	margin-bottom: 30px;
}

.question-text {
	display: block;
	margin-bottom: 22px;
}
.option-input {
	display: block;
	margin-bottom: 16px;
	border: none;
	outline: none;
	border-bottom: 1px solid rgba(69, 68, 162, 0.3);
	font-family: 'Lato', sans-serif;
	font-weight: 500;
	font-size: 17px;
	line-height: 20px;
	color: #4544a2;
}

.question-div div {
	width: 50%;
}

.question-div div span {
	display: flex;
}
.question-div div span i {
	color: #e9ecf2;
	padding-right: 10px;
}

.question-div h1 {
	font-size: 17px;
	line-height: 20px;
	margin-bottom: 10px;
}

.question-btns {
	display: flex;
}
.question-btns button {
	margin-right: 10px;
	background: #6871e9;
	border: none;
	outline: none;
	cursor: pointer;
	padding: 10px;
	border-radius: 15px;
	color: #fff;
	font-size: 12px;
}

.question-div div input[type="radio"] {
	accent-color: #4544a2;
}
.correct-option-label {
	font-size: 17px;
	line-height: 20px;
	color: rgba(38, 50, 56, 0.5);
	margin-right: 30px;
	padding-left: 10px;
}

.new-question-btn {
	background: #4544a2;
	border-radius: 10px;
	border: none;
	outline: none;
	cursor: pointer;
	font-family: 'Lato', sans-serif;
	font-weight: 500;
	font-size: 12px;
	line-height: 100%;
	color: #fff;
	padding: 10px 15px;
}

.submit-btns {
	width: 90%;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	margin-top: 10px;
}
.submit-btns button {
	margin-right: 20px;
	cursor: pointer;
	background: #4544a2;
	box-shadow: 0px 4px 5px rgba(69, 68, 162, 0.25);
	border-radius: 12px;
	font-family: 'Lato', sans-serif;
	font-weight: 500;
	font-size: 18px;
	line-height: 100%;
	padding: 15px 20px;
	color: #fff;
	border: none;
	outline: none;
}

.AdminMentorRequests {
	height: calc(80vh);
	padding-top: 15px;
	padding-left: 35px;
	width: auto;
	overflow-y: scroll;
}

/* width */
.AdminMentorRequests::-webkit-scrollbar {
	width: 6px;
}

/* Track */
.AdminMentorRequests::-webkit-scrollbar-track {
	background: #f1f1f1;
}

/* Handle */
.AdminMentorRequests::-webkit-scrollbar-thumb {
	background: rgba(0, 0, 0, 0.4);
	border-radius: 15px;
}

/* Handle on hover */
.AdminMentorRequests::-webkit-scrollbar-thumb:hover {
	background: #555;
}

.no-data-msg {
	color: #4544a2;
	font-weight: 600;
	font-size: 18px;
}

.AdminMentorRequests h1 {
	font-family: 'Lato', sans-serif;
	font-weight: 700;
	font-size: 20px;
	line-height: 24px;
	color: #2e353a;
	margin-bottom: 20px;
}

.mentor-request-card {
	position: relative;
	display: flex;
	justify-content: space-around;
	font-family: 'Lato', sans-serif;
	border: 1px solid #d7def0;
	box-sizing: border-box;
	border-radius: 15px;
	padding: 20px;
	width: auto;
}

.close-request-btn {
	position: absolute;
	top: 10px;
	right: 20px;
	background: none;
	border: none;
	outline: none;
	cursor: pointer;
}
.close-request-btn i {
	color: #fb174e;
	font-size: 18px;
}

.mentor-request-card span h2 {
	font-weight: 400;
	font-size: 20px;
	line-height: 24px;
	color: rgba(46, 53, 58, 0.7);
}
.mentor-request-card span h3 {
	font-weight: 700;
	font-size: 20px;
	line-height: 24px;
	color: #2e353a;
	margin-bottom: 20px;
}
.mentor-request-card span a {
	background: #4544a2;
	border-radius: 5px;
	text-decoration: none;
	padding: 5px 10px;
	color: #fff;
	font-size: 14px;
}
.mentor-request-card span a i {
	color: #fff;
	padding-right: 14px;
}

@media only screen and (max-width: 768px) {
	.AdminMentorRequests {
		padding-left: 25px;
		padding-right: 10px;
	}
}

.AdminResumeReview {
	padding-top: 15px;
	padding-left: 40px;
	height: calc(80vh);
	overflow-y: auto;
}

/* width */
.AdminResumeReview::-webkit-scrollbar {
	width: 6px;
}

/* Track */
.AdminResumeReview::-webkit-scrollbar-track {
	background: #f1f1f1;
}

/* Handle */
.AdminResumeReview::-webkit-scrollbar-thumb {
	background: rgba(0, 0, 0, 0.4);
	border-radius: 15px;
}

/* Handle on hover */
.AdminResumeReview::-webkit-scrollbar-thumb:hover {
	background: #555;
}

.AdminResumeReview h1 {
	font-family: 'Lato', sans-serif;
	font-weight: 700;
	font-size: 20px;
	line-height: 24px;
	color: #2e353a;
	margin-bottom: 30px;
}

.review-card {
	display: flex;
	justify-content: space-between;
	width: -webkit-fit-content;
	width: -moz-fit-content;
	width: fit-content;
	padding: 20px;
	border: 1px solid #d5d7d8;
	border-radius: 15px;
	font-family: 'Lato', sans-serif;
	font-weight: 400;
}
.review-card h2 {
	font-size: 20px;
	line-height: 24px;
	color: #2e353a;
}
.review-card p {
	font-size: 16px;
	line-height: 19px;
	color: rgba(46, 53, 58, 0.7);
}
.review-card button {
	margin-left: 30px;
	background: #4544a2;
	border-radius: 8.5px;
	padding: 15px 20px;
	border: none;
	outline: none;
	color: #fff;
	font-family: 'Lato', sans-serif;
	font-weight: 700;
	font-size: 14px;
	line-height: 100%;
	cursor: pointer;
}

/* Resume Review Page */
.ResumeReview {
	height: calc(90vh);
	width: 100%;
	padding-bottom: 20px;
	overflow-y: auto;
}

.resume-pdf-container {
	height: 100%;
	overflow-y: scroll;
	position: relative;
}

/* width */
.resume-pdf-container::-webkit-scrollbar {
	width: 8px;
}

/* Track */
.resume-pdf-container::-webkit-scrollbar-track {
	background: #f1f1f1;
}

/* Handle */
.resume-pdf-container::-webkit-scrollbar-thumb {
	background: rgba(0, 0, 0, 0.4);
	border-radius: 15px;
}

/* Handle on hover */
.resume-pdf-container::-webkit-scrollbar-thumb:hover {
	background: #555;
}

.resume-pdf-container canvas {
	max-width: 100%;
	height: inherit !important;
	display: block;
}
.resume-pdf {
	display: flex;
	flex-direction: column;
	align-items: center;
}
.page-nav {
	z-index: 50;
	position: absolute;
	top: 0;
	left: 40%;
	transition: all ease-in-out 500ms;
	box-shadow: 0 30px 40px 0 rgb(16 36 94 / 20%);
	border-radius: 4px;
	background-color: #d7def0;
	color: #4544a2;
	display: flex;
	padding: 10px;
	font-weight: 600;
}
.page-nav p {
	margin: 0 10px;
}
.page-nav button {
	cursor: pointer;
	background: none;
	border: none;
	outline: none;
}
.page-nav button i {
	color: #4544a2;
}

.review-text-div {
	padding-top: 55px;
	padding-left: 45px;
	width: 100%;
}
.review-text-div span {
	display: flex;
	justify-content: space-between;
}
.review-text-div span button {
	display: none;
	background: none;
	color: #4544a2;
	box-shadow: none;
}
.review-text-div h1 {
	font-family: 'Lato', sans-serif;
	font-weight: 700;
	font-size: 15px;
	line-height: 18px;
	margin-bottom: 20px;
}
.review-text-div textarea {
	background: rgba(233, 236, 242, 0.4);
	border: 1px solid #e9ecf2;
	box-sizing: border-box;
	border-radius: 12px;
	outline: none;
	padding: 22px 16px;
	font-family: 'Lato', sans-serif;
	font-weight: 500;
	font-size: 15px;
	line-height: 18px;
	color: #4544a2;
	margin-bottom: 45px;
}
.review-text-div textarea::-webkit-input-placeholder {
	color: rgba(69, 68, 162, 0.3);
}
.review-text-div textarea:-ms-input-placeholder {
	color: rgba(69, 68, 162, 0.3);
}
.review-text-div textarea::placeholder {
	color: rgba(69, 68, 162, 0.3);
}
.review-text-div button {
	display: block;
	background: #4544a2;
	box-shadow: 0px 4px 5px rgba(69, 68, 162, 0.25);
	border-radius: 12px;
	border: none;
	outline: none;
	cursor: pointer;
	font-family: 'Lato', sans-serif;
	font-weight: 500;
	font-size: 18px;
	line-height: 100%;
	color: #fff;
	padding: 15px 20px;
}

@media only screen and (max-width: 900px) {
	.review-text-div {
		padding-top: 10px;
		padding-left: 20px;
	}
	.review-text-div button {
		display: none;
	}
	.review-text-div span button {
		display: block;
	}
	.review-text-div textarea {
		width: 100%;
		margin-bottom: 30px;
	}
}

.AdminInterviewRequests {
    height: calc(80vh);
    width: auto;
    padding-top: 13px;
    padding-left: 40px;
    overflow-y: auto;
}

/* width */
.AdminInterviewRequests::-webkit-scrollbar {
    width: 6px;
}

/* Track */
.AdminInterviewRequests::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
.AdminInterviewRequests::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.4);
    border-radius: 15px;
}

/* Handle on hover */
.AdminInterviewRequests::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.AdminInterviewRequests h1 {
    font-family: 'Lato', sans-serif;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #2e353a;
    margin-bottom: 28px;
}

.AdminCandidateList {
    height: calc(80vh);
    width: auto;
    padding-top: 13px;
    padding-left: 40px;
    overflow-y: auto;
}

.AdminCandidateList h1 {
    font-family: 'Lato', sans-serif;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #2e353a;
    margin-bottom: 28px;
}

.interview-requests {
    width: auto;
    margin: 0 auto;
}

.interview-request-card {
    display: flex;
    border: 1px solid #d7def0;
    box-sizing: border-box;
    border-radius: 15px;
    padding: 14px 19px;
    font-family: 'Lato', sans-serif;
}
.interview-request-card h2 {
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    color: #2e353a;
}

.admin-card {
    display: flex;
    border: 1px solid #d7def0;
    box-sizing: border-box;
    border-radius: 15px;
    padding: 14px 19px;
    font-family: 'Lato', sans-serif;
}
.admin-card h2 {
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    color: #2e353a;
}
.request-card-info p {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: rgba(46, 53, 58, 0.7);
}
.request-card-info button {
    margin-top: 10px;
    color: #4544a2;
    background: none;
    border: none;
    outline: none;
    font-family: 'Lato', sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    cursor: pointer;
}

.admin-card-info p {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: rgba(46, 53, 58, 0.7);
}
.admin-card-info button {
    margin-top: 10px;
    padding: 1rem;
    color: #4544a2;
    background: none;
    border: none;
    outline: none;
    font-family: 'Lato', sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    cursor: pointer;
}

.request-card-btns {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    padding-left: 10px;
}
.request-card-btns i {
    color: #fb174e;
    cursor: pointer;
    font-size: 20px;
}
.request-card-btns span {
    display: flex;
}
.request-card-btns span i:first-of-type {
    color: #42ba96;
    padding-right: 20px;
}
.request-card-btns button {
    background: #4544a2;
    border-radius: 5px;
    border: none;
    outline: none;
    cursor: pointer;
    font-family: 'Lato', sans-serif;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #fff;
    padding: 5px 10px;
}
.request-card-btns button i {
    color: #fff;
    padding-right: 10px;
    display: inline;
    font-size: 14px;
}

.dialog-btns button {
    margin-right: 20px;
}

/* Candidate Profile */
.CandidateProfile {
    position: relative;
    height: calc(75vh);
    width: auto;
    padding-top: 50px;
    padding-left: 35px;
    overflow-y: scroll;
}

/* width */
.CandidateProfile::-webkit-scrollbar {
    width: 6px;
}

/* Track */
.CandidateProfile::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
.CandidateProfile::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.4);
    border-radius: 15px;
}

/* Handle on hover */
.CandidateProfile::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.close-profile {
    position: absolute;
    top: 10px;
    right: 8%;
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
}
.close-profile i {
    color: #fb174e;
    font-size: 25px;
}

.profile-overview {
    display: flex;
    font-family: 'Lato', sans-serif;
}
.profile-overview img {
    width: 150px;
    height: 150px;
    border-radius: 15px;
}
.profile-overview span {
    padding-left: 30px;
}
.profile-overview span h1 {
    font-weight: 700;
    font-size: 40px;
    line-height: 48px;
    color: #2e353a;
    margin-bottom: 7px;
}
.profile-overview span p {
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: rgba(38, 50, 56, 0.5);
    margin-bottom: 5px;
}

.candidate-details {
    margin-top: 40px;
    display: flex;
}

.details-section-1 {
    width: 50%;
    border-right: 1px solid #d7def0;
    padding-right: 30px;
}
.details-section-2 {
    width: 50%;
    padding-left: 20px;
    padding-right: 10px;
}

.candidate-details h2 {
    font-family: 'Lato', sans-serif;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    color: rgba(69, 68, 162, 0.3);
    border-bottom: 1px solid #e9ecf2;
    margin-bottom: 10px;
    margin-top: 35px;
}

.edu-details p {
    font-family: 'Lato', sans-serif;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #2e353a;
    margin-bottom: 5px;
}

.preference-details p {
    font-family: 'Lato', sans-serif;
    font-weight: 600;
    font-size: 19px;
    line-height: 24px;
    color: #2e353a;
    margin-bottom: 8px;
}
.preference-details p span {
    font-weight: 400;
    color: rgba(46, 53, 58, 0.7);
}

.experience-details p {
    font-family: 'Lato', sans-serif;
    font-weight: 400;
    font-size: 16px;
    color: rgba(38, 50, 56, 0.7);
}
.experience-details p:first-of-type {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #2e353a;
    margin-bottom: 5px;
}

.skill-details ul {
    list-style: none;
}
.skill-details ul li {
    font-family: 'Lato', sans-serif;
    font-weight: 400;
    font-size: 19px;
    line-height: 24px;
    color: rgba(38, 50, 56, 0.7);
    margin-bottom: 8px;
}
.skill-details ul li span {
    color: #2e353a;
    font-weight: 600;
}

.project-details .project-card span {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
}
.project-details .project-card span h3 {
    font-family: 'Lato', sans-serif;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: #2e353a;
}
.project-details .project-card span a i {
    color: #6871e9;
}
.project-details .project-card p {
    font-family: 'Lato', sans-serif;
    font-weight: 400;
    font-size: 12px;
    line-height: 130%;
    color: rgba(46, 53, 58, 0.7);
}

@media only screen and (max-width: 992px) {
    .CandidateProfile {
        height: calc(70vh);
    }
}

@media only screen and (max-width: 768px) {
    .CandidateProfile {
        padding-left: 20px;
    }
    .candidate-details {
        margin-top: 0;
        flex-direction: column;
    }
    .details-section-1 {
        width: auto;
        border-right: none;
        padding-right: 20px;
    }
    .details-section-2 {
        width: auto;
        padding-left: 0;
        padding-right: 20px;
    }
}

.AdminProjects {
	width: auto;
	height: calc(80vh);
	overflow-y: auto;
	padding-top: 34px;
	padding-left: 32px;
}

/* width */
.AdminProjects::-webkit-scrollbar {
	width: 6px;
}

/* Track */
.AdminProjects::-webkit-scrollbar-track {
	background: #f1f1f1;
}

/* Handle */
.AdminProjects::-webkit-scrollbar-thumb {
	background: rgba(0, 0, 0, 0.4);
	border-radius: 15px;
}

/* Handle on hover */
.AdminProjects::-webkit-scrollbar-thumb:hover {
	background: #555;
}

.AdminProjects h1 {
	font-family: 'Lato', sans-serif;
	font-weight: 700;
	font-size: 20px;
	line-height: 24px;
	color: #2e353a;
	margin-bottom: 25px;
}

.AdminPanel {
    width: 100vw;
    height: 100vh;
    display: flex;
}

/* Sidebar */
.Admin-sidebar {
    width: 15%;
    height: 100%;
    /* float: left; */
    border: 1px solid #d7def0;
    padding-top: 26px;
    padding-left: 32px;
    padding-right: 32px;
    overflow: auto;
}

.sidebar-logo {
    height: 35px;
    width: 100%;
    text-align: start;
    padding-left: 10px;
    margin-bottom: 68px;
}
.sidebar-logo img {
    height: 100%;
}

.Admin-sidebar button {
    display: block;
    width: 187px;
    height: 38px;
    background: none;
    border: none;
    outline: none;
    border-radius: 4px;
    font-family: 'Lato', sans-serif;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    color: #d7def0;
    cursor: pointer;
    text-align: start;
    padding: 12px;
    margin-bottom: 15px;
}
.Admin-sidebar button.btn-selected {
    background: #d7def0;
    color: #4544a2;
    transition: all 500ms ease;
}
.Admin-sidebar button i {
    padding-right: 20px;
    font-size: 16px;
}

.sidebar-menu-icon {
    display: none;
}
@media only screen and (max-width: 992px) {
    body {
        overflow-x: hidden;
    }
    .AdminPanel {
        flex-direction: column;
    }
    .Admin-main .content-nav {
        display: none;
    }

    .Admin-sidebar {
        width: 100%;
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
        padding-bottom: 20px;
        padding-left: 20px;
    }
    .sidebar-logo {
        width: 90%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 0;
    }
    .sidebar-logo img {
        height: 35px;
    }
    .sidebar-menu-icon {
        display: block;
        cursor: pointer;
        color: #4544a2;
        padding-right: 20px;
    }
    .admin-sidebar-btns {
        display: none;
    }

    .Admin-main {
        width: 100% !important;
    }
}

/* Main */
.Admin-main {
    width: 80%;
    height: 100%;
    /* float: right; */
}

.content-nav {
    height: 50px;
    width: 100%;
    border-bottom: 1px solid #d7def0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 25px 0;
}

.content-nav button {
    margin-right: 38px;
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
}
.content-nav button i {
    font-size: 17px;
    color: rgba(46, 53, 58, 0.7);
}

.content-nav div {
    display: flex;
    align-items: center;
    padding-right: 50px;
    font-family: 'Lato', sans-serif;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: rgba(38, 50, 56, 0.5);
}
.content-nav div i {
    font-size: 30px;
    padding-right: 10px;
    color: #2e353a;
}
.content-nav div span p:first-child {
    color: #404040;
}

/* Notifications */
.notification {
    padding: 5px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.unread {
    height: 50px;
    width: 50px;
    border-radius: 50%;
    color: red;
    z-index: 50;
}

.open-notif {
    font-size: 13px;
    padding-top: 20px;
    color: #4544a2;
    text-decoration: underline;
}

.notif-modal h1 {
    font-family: 'Lato', sans-serif;
    font-size: 18px;
    font-weight: 800;
    color: #2e353a;
    margin-top: 30px;
}

.notif-modal p {
    font-family: 'Lato', sans-serif;
    font-size: 16px;
    font-weight: 500;
    margin: 15px 0;
}
.notif-modal p span {
    font-weight: 600;
}

.notif-modal button {
    cursor: pointer;
    border: none;
    outline: none;
    background-color: #4544a2;
    color: #fff;
    border-radius: 15px;
    padding: 5px 10px;
}

.Bootcamp {
    width: auto;
    height: 100%;
}

/* Hero Section */

.bootcamp-hero {
    height: calc(110vh - var(--nav-height));
    width: 100%;
    padding-right: 20px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

@media only screen and (min-width: 992px) {
    .bootcamp-hero {
        height: calc(100vh - var(--nav-height));
    }
}

.hero-img {
    text-align: center !important;
}

.why-options {
    justify-content: center !important;
    margin-top: 3vw !important;
}

.hero-why-options {
    margin-left: 0.5vw !important;
    margin-right: 0.5vw !important;
}

.hero-why-options h5 {
    width: 25vw;
    font-size: 16px;
}

@media only screen and (max-width: 500px) {
    .hero-why-options h5 {
        width: 25vw;
        font-size: 13px;
    }
}

@media only screen and (min-width: 992px) {
    .why-options {
        justify-content: left !important;
        margin-top: 0vw !important;
    }

    .hero-why-options h5 {
        width: 12vw;
    }
}

.bootcamp-hero-img {
    height: 270px;
    margin: 1vw;
}

@media only screen and (min-width: 992px) {
    .bootcamp-hero-img {
        height: 392px;
    }
}

.hero-content {
    text-align: center;
}

@media only screen and (min-width: 992px) {
    .hero-content {
        text-align: left;
    }
}

.bootcamp-hero h1 {
    font-family: 'Lato', sans-serif;
    font-weight: 400;
    font-size: 5vw;
    line-height: 48px;
    color: #4544a2;
    font-weight: 600;
    margin-bottom: 0.5vw;
}

.bootcamp-hero p {
    margin-bottom: 4.5vw;
    margin-top: 1.5vw;
    margin-left: 5vw;
    margin-right: 5vw;
    font-size: 3.7v;
}

.bootcamp-hero h3 {
    margin-bottom: 1.5vw;
}

@media only screen and (min-width: 992px) {
    .bootcamp-hero h1 {
        font-size: 2.5rem;
    }

    .bootcamp-hero p {
        margin-bottom: 2.1vw;
        margin-left: 0;
        margin-top: 2.5vw;
        font-size: 23px;
    }

    .bootcamp-hero h3 {
        margin-bottom: 1.5vw;
        font-size: 26px;
    }
}

.bootcamp-hero button {
    background-color: #4544a2;
    color: #fff;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 20px 35px;
    border-radius: 15px;
    margin-top: 40px;
    font-family: 'Lato', sans-serif;
    font-weight: 500;
    font-size: 16px;
    line-height: 100%;
}

.bootcamp-details h1 {
    font-size: 5vw;
    text-align: center !important;
    color: #4544a2;
    margin-top: 10vw;
    margin-bottom: 4vw;
}

@media only screen and (min-width: 992px) {
    .bootcamp-details h1 {
        margin-top: 6vw;
        font-size: 2rem;
        color: #4544a2;
        margin-bottom: 0vw !important;
    }
}

.bootcamp-cards {
    margin: 5vh auto !important;
    text-align: center;
}

.bootcamp-cards .title {
    color: #4544a2 !important;
    font-weight: 500;
    margin-top: 1vw;
}

.action-btn {
    justify-content: center;
}

.bootcamp-cards .btn {
    background-color: #4544a2;
    color: #fff;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 10px 25px;
    border-radius: 15px;
    margin-bottom: 15px;
    font-family: 'Lato', sans-serif;
    font-weight: 500;
    font-size: 16px;
    line-height: 100%;
}

.swiper {
    width: 100%;
    height: 100%;
}

.swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;

    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
}

.swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.mySwiper img {
    height: 4vw;
    width: auto;
    padding: 1vw;
}

@media only screen and (min-width: 992px) {
    .mySwiper img {
        height: 3vw;
        width: auto;
    }
}

.mySwiper {
    margin-top: 5vw;
    margin-left: auto;
    margin-right: auto;
}

.benefits img {
    height: 3.3vw;
    width: auto;
    margin-right: 1vw;
}

@media only screen and (min-width: 992px) {
    .benefits img {
        height: 1.2vw;
        width: auto;
        margin-right: 1vw;
    }
}

.benefits-item {
    margin: 1vw;
    padding: 0.5vw;
    text-align: center;
    justify-content: center;
}

.benefits {
    margin-top: -2vw;
    text-align: center;
}

.bootcamp-brands {
    padding: 5vw;
    margin-top: 5vw;
}

.marketing-partner {
    margin-top: 5vw;
    text-align: center;
    vertical-align: middle;
}

.marketing-partner h4 {
    margin-bottom: 2rem;
}

.marketing-partner img {
    height: 4rem;
}

@media only screen and (max-width: 992px) {
    .marketing-partner img {
        height: 2rem;
    }

    .marketing-partner h4 {
        margin-bottom: 1rem;
    }
}

.Bootcamp-detail {
  width: auto;
  height: 100%;
  /* Hero Section */
}

.Bootcamp-detail .bootcamp-hero {
  height: calc(100vh - var(--nav-height));
  width: 100%;
  padding-right: 20px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  background-color: rgba(199, 199, 227, 0.24);
}

.Bootcamp-detail .hero-img {
  text-align: center !important;
}

.Bootcamp-detail .why-options {
  justify-content: center !important;
  margin-top: 3vw !important;
  color: #fff;
  background-color: #4544a2;
  align-items: center;
  padding: 10vw;
}

.Bootcamp-detail .hero-why-options {
  margin-left: 1vw !important;
  margin-right: 1vw !important;
  padding: 5vw;
}

.Bootcamp-detail .hero-why-options h5 {
  width: 40vw;
}

@media only screen and (min-width: 992px) {
  .Bootcamp-detail .why-options {
    justify-content: center !important;
    margin-top: 0vw !important;
    color: #fff;
    background-color: #4544a2;
    align-items: center;
    padding: 5vw;
  }
  .Bootcamp-detail .hero-why-options h5 {
    width: 15vw;
    font-size: 1vw;
    font-weight: 500;
  }
  .Bootcamp-detail .hero-why-options {
    margin-left: 2vw !important;
    margin-right: 2vw !important;
    padding: 0vw;
  }
}

.Bootcamp-detail .bootcamp-hero-img {
  /* height: 27px; */
}

@media only screen and (min-width: 992px) {
  .Bootcamp-detail .bootcamp-hero-img {
    height: 500px;
    margin-top: 3vw;
    margin-left: 8.6vw;
  }
  .cropped1 {
    width: 100%; /* width of container */
    height: 30px; /* height of container */
    object-fit: cover;
    border: 5px solid black;
}
  
}
@media only screen and (max-width: 500px) {

  .bootcampRoutine_mobile{
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: rgba(199, 199, 227, 0.12);
    padding: 7vw 0rem;
  }

  .boot_heading_m{
    text-align: center;
    margin-bottom: 5vw;
  }

  .boot_heading_m h1{

    font-style: normal;
    font-weight: 700;
    font-size: 25px;
    /* line-height: 100px; */
    /* identical to box height, or 40px */
    
    text-align: center;
    
    color: #4544A2;
  }
  .boot_heading_m p{

    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 100%;
    /* identical to box height, or 40px */
    
    text-align: center;
    
    color: #000000;
  }

  .bootcamp_details_m{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    /* border: 1px solid black; */
    background: #ffffff;
    margin: 1rem 0rem;
    width: 21rem;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding: 12px 15px;
    border-radius: 10px;
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.18);
  }




  .bootcamp_img_m{
    width: 80px;
    height: 80px;
  }
  .bootcamp_img_m img{
    width: 80px;
    height: 80px;
  }

  .bootcamp_head_m{
    margin: 1rem 0rem;
  }
  .bootcamp_content_m{
    /* margin: 1rem 0rem 0rem 0rem; */
  }

  .bootcamp_content_m p{
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 25px;
/* or 140% */


  color: #000000;
  }
  .bootcamp_head_m h1{
    font-style: normal;
    font-weight: 700;
    font-size: 25px;
    line-height: 100%;
    /* identical to box height, or 30px */
    
    /* text-align: center; */
    
    color: #4544A2;
  }



  .Bootcamp-detail .programWorking h2 {
    padding-left: 0 !important;
    text-align: start !important;
  }
  .Bootcamp-detail .mentor-options{
    padding: 0vw 4vw 4vw 4vw !important;
  }
  .Bootcamp-detail .bootcamp-hero button{
    margin-top: 30px !important;
  }


 .bootcampRoutine{
   display: none !important;
 }

}

.Bootcamp-detail .hero-content {
  text-align: center;
}

@media only screen and (min-width: 992px) {
  .Bootcamp-detail .hero-content {
    text-align: left;
    /* padding-left: 10vw; */
    margin-top: 7vw !important;
  }
}

.Bootcamp-detail .bootcamp-hero h1 {
  font-family: 'Lato', sans-serif;
  font-weight: 400;
  font-size: 5vw;
  line-height: 48px;
  color: #4544a2;
  font-weight: 600;
  margin-bottom: 0.5vw;
}

.Bootcamp-detail .bootcamp-hero p {
  margin-bottom: 4.5vw;
  margin-top: 1.5vw;
  margin-left: 5vw;
  margin-right: 5vw;
  font-size: 3.7vw;
}

.Bootcamp-detail .bootcamp-hero h3 {
  margin-bottom: 1.5vw;
}

@media only screen and (min-width: 992px) {
  .Bootcamp-detail .bootcamp-hero h1 {
    font-size: 2.5rem;
  }
  .Bootcamp-detail .bootcamp-hero p {
    margin-bottom: 2.1vw;
    margin-left: 0;
    margin-top: 2.5vw;
    font-size: 1.38vw;
  }
  .Bootcamp-detail .bootcamp-hero h3 {
    margin-bottom: 1.5vw;
  }
}

.Bootcamp-detail .bootcamp-hero button {
  background-color: #4544a2;
  color: #fff;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 20px 35px;
  border-radius: 15px;
  margin-top: 50px;
  font-family: 'Lato', sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
}

.Bootcamp-detail .programWorking {
  padding: 4vw;
  text-align: center !important;
}

@media only screen and (min-width: 992px) {
  .Bootcamp-detail .programWorking {
    /* padding: 7vw; */
  }
}

.programWorking{

}

.Bootcamp-detail .programWorking h2 {
  padding-left: 7vw;
  font-size: 5vw;
  text-align: center ;
  color: #4544a2;
  margin-top: 2vw;
  margin-bottom: 4vw;
}

@media only screen and (min-width: 992px) {
  .Bootcamp-detail .programWorking h2 {
    margin-top: 0vw;
    font-size: 2rem;
    padding-left: 0vw;
    text-align: left !important;
    color: #4544a2;
    margin-bottom: 3vw;
  }
}

.Bootcamp-detail .programWorking .content {
  font-size: 4vw;
  margin-top: 4vw !important;
  text-align: justify !important;
  /* padding: 5vw; */
  margin-bottom: 10vw;
}

/* .programWorking{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
} */

@media only screen and (min-width: 992px) {
  .Bootcamp-detail .programWorking .content {
    font-size: 1.3vw;
    max-width: 50vw;
    text-align: justify !important;
    margin-bottom: 1vw;
    /* margin-top: 0vw !important; */
    padding-left: 18vw;
  }
}

.Bootcamp-detail .programWorking img {
  height: 40vw;
}

@media only screen and (min-width: 992px) {
  .Bootcamp-detail .programWorking img {
    height: 20vw;
    /* object-fit: cover; */
  }
}

.Bootcamp-detail .certi {
  /* background-color: #ffffff */
}

.Bootcamp-detail .certi .image {
  text-align: center;
}

.Bootcamp-detail .certi .content {
  text-align: left !important;
}

.Bootcamp-detail .certi img {
  height: 60vw !important;
  text-align: center;
}

@media only screen and (min-width: 992px) {
  .Bootcamp-detail .certi img {
    height: 25vw !important;
  }
}

.Bootcamp-detail .hero-img {
  text-align: center !important;
}

.Bootcamp-detail .mentor-options {
  justify-content: center !important;
  /* margin-top: 3vw !important; */
  align-items: center;
  padding: 10vw;
  text-align: center;
}

.Bootcamp-detail .hero-mentor-options {
  margin-left: 1vw !important;
  margin-right: 1vw !important;
  padding: 5vw;
  text-align: center !important;
}

.Bootcamp-detail .mentors {
  text-align: center;
  margin-bottom: 5vw;
  padding: 2vw 0vw;
  background: rgba(199, 199, 227, 0.12);
}

.Bootcamp-detail .mentors h2 {
  font-size: 5vw;
  text-align: center !important;
  color: #4544a2;
  margin-top: 4vw;
  margin-bottom: 4vw;
}

@media only screen and (min-width: 992px) {
  .Bootcamp-detail .mentors h2 {
    /* margin-top: 6vw; */
    font-size: 2rem;
    color: #4544a2;
    margin-bottom: 0vw !important;
  }
}

.Bootcamp-detail .hero-mentor-options h5 {
  width: 40vw;
  font-weight: 700;
  font-size: 5vw;
  color: #4544a2;
  margin-top: 2vw;
  text-align: center !important;
}

@media only screen and (min-width: 992px) {
  .Bootcamp-detail .mentor-options {
    justify-content: center !important;
    margin-top: 0vw !important;
    /* margin-top: 3vw !important; */
    align-items: center;
    padding: 3vw;
  }
  .Bootcamp-detail .hero-mentor-options h5 {
    width: 15vw;
    font-size: 1.2rem;
    font-weight: 700;
    margin-top: 0.5rem;
  }
  .Bootcamp-detail .hero-mentor-options p {
    width: 15vw;
    margin-top: 0.5rem;
  }
  .Bootcamp-detail .hero-mentor-options {
    margin-left: 2vw !important;
    margin-right: 2vw !important;
    padding: 0vw;
  }
}
/*# sourceMappingURL=bootcampDetails.css.map */

.bootcampRoutine{
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  background: rgba(199, 199, 227, 0.12);
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding: 2rem 0rem;
}

.bootcamp_heading{
  margin: 3vw 0vw 0vw 0vw;
  height: 3rem;
}

.bootcamp_content{
  
}


.bootcamp_heading h1{
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 100%;
  /* or 30px */
  
  text-align: center;
  
  color: #4544A2;
}

.bootcamp_content ul{
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 40px;
  margin-left: 2rem;
  margin-top: 2rem;
  /* or 140% */
  
  
  color: #000000;
}

.bootcamp_img{
  width: 300px;
  height: 300px;
  border-radius: 50%;
  background: #FFFFFF;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.bootcamp_img img{
  /* clip-path: circle(50.0% at 50% 50%); */
}


.boot_heading {
  text-align: center;
  margin-bottom: 2rem;
}
.boot_heading h1{
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-size: 35px;
  line-height: 4rem;
  margin: 0;
  padding: 0;
  /* identical to box height, or 40px */
  
  text-align: center;
  
  color: #4544A2;
}
.boot_heading p{
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 100%;
  /* identical to box height, or 40px */
  
  text-align: center;
  
  color: #000000;
}

.bootcamp_detail{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.bootcampRoutine_mobile{
  display: none;
}
.blogs{
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 90px 10px;
}



.blog-card {
  background: #FFFFFF;
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  margin: 2rem;
  /* z-index: 99; */
  /* margin-top: 6rem; */
  /* width: 58rem; */
}

/* @media (max-width: 960px) {
  .blog-card {
    width: 90%;
    margin: 4rem 0;
  }
} */


@media (max-width: 500px) {
  .blog-card {
   display: flex;
   flex-direction: column ;
  }
}

.blog_container {
    display: flex;
    width: 60rem;
    height: 14rem;
    /* margin-right: 5rem; */
}

.blog-item__blog p {
    /* line-height:1px; */
    height: 40px;
    width: 100%;
    word-wrap: break-word;
}

.blog-item__blog-container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    overflow: hidden;
}

.blogs-out-even {
    background: rgba(199, 199, 227, 0.24);
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    width: 64rem;
    height: 18rem;
    margin: 4rem 4rem 0rem 0rem;
    display: flex;
    z-index: 99;
    float: left;
}
.blog-out-odd {
    background: rgba(199, 199, 227, 0.24);
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    width: 64rem;
    height: 18rem;
    margin: 4rem 0rem 0rem 4rem;
    display: flex;
    z-index: 99;
    float: right;
}

.blog_container-odd {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    /* margin-left: 2rem; */
    width: 60rem;
    height: 14rem;
}

.blog-item__img-container-odd {
    width: 14rem;
    height: 14rem;
    /* margin-right: 2rem; */
}
.blog-item__img-container-odd img {
    width: 14rem;
    height: 14rem;
    border-radius: 0px 10px 10px 0px;
}

.blog-item__blog-container-odd {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-left: 2rem;
}

.blog-item__img-container {
    width: 14rem;
    height: 14rem;
    margin-right: 2rem;
}

.blog-item__img-container img {
    width: 14rem;
    height: 14rem;
    border-radius: 10px 0px 0px 10px;
}

.blog-item__title h1 {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    /* identical to box height */

    letter-spacing: 0.075em;

    color: #000000;
}

.blog-item__title p {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    /* identical to box height */

    letter-spacing: 0.075em;

    color: #dadada;
}

.blog-item__blog p {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    width: 90%;
    word-wrap: break-word;
    color: #000000;
}

.blog-item__actions button {
    outline: none;
    border: none;
    background: none;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    /* identical to box height */
    cursor: pointer;
    letter-spacing: 0.075em;

    color: #000000;
}

.image {
    position: relative;
    top: 15%;
    /* right: 3%; */
    z-index: -1;
}

.image-odd {
    position: relative;
    top: 15%;
    right: 112%;
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    z-index: -1;
}

.blog_images {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    /* margin: 1rem 0rem; */
}

.blog_images img {
    margin: 1rem 4rem;
}

@media (max-width: 500px) {
    .image,
    .image-odd {
        display: none;
    }
    .blogs-out-even,
    .blog-out-odd {
        background: rgba(199, 199, 227, 0.24);
        box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.25);
        border-radius: 10px;
        width: 23rem;
        height: 19rem;
        margin: auto;

        /* margin-right: 1rem; */
        /* margin: 1rem; */
        /* margin: 0rem; */
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;
        /* z-index: 99; */
        float: none;
        margin-bottom: 2rem;
    }

    .blog_container,
    .blog_container-odd {
        display: flex;
        flex-direction: column;
        width: 20rem;
        height: 17rem;
        /* justify-content: center;  */
        /* align-items: center; */
    }

    .blog-item__img-container,
    .blog-item__img-container-odd {
        width: 18rem;
        height: 7.5rem;
    }
    .blog-item__img-container img,
    .blog-item__img-container-odd img {
        width: 20rem;
        height: 7.5rem;
        border-radius: 10px 10px 0px 0px;
    }

    .blog-item__title h1 {
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 21px;
        letter-spacing: 0.075em;

        color: #000000;
    }

    .blog-item__title p {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        /* identical to box height */
        margin: 0 !important;
        letter-spacing: 0.075em;
        color: #dadada;
    }

    .blog-item__blog p {
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 17px;

        color: #000000;
    }

    .blog-item__blog-container,
    .blog-item__blog-container-odd {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        /* align-items: center; */
        height: 10rem;
        padding: 1rem;
        margin: 0 !important;
    }
    .blog-filter,
    .blog-header {
        display: none;
    }

    .blog-heading {
        margin: 1rem 0rem !important;
    }
}

.blog-heading {
  margin: 2rem 0rem 0rem 0rem;
}

.blog-heading{
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  /* identical to box height */
  
  letter-spacing: 0.075em;
  
  color: #000000;
}
.blog-item-even{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  
}

@media (max-width:500px){
  ul{
    padding: 0;
  }
}
.blog_detail{
    height: 100%;
    /* display: flex; */
    /* justify-content: space-between; */
    /* align-items: center; */
    padding-top: 90px;
}


.details_Container{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.detail_image{
    width: 100%;
    height: 30rem;
  
}
.detail_image img{
    width: 100%;
    height: 30rem;
    position: absolute;
    z-index: -1;
}



.all_blogs{
    display: flex;
    flex-direction: column;
}

.blog_info{
    /* position:absolute; */
    /* top: 100%; */
    /* left: 0;  */
    /* right: 0;    */
    /* height: fit-content; */
    /* margin-left: auto; 
    margin-right: auto;  */
    width: 95%;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    overflow: hidden;
    word-wrap: break-word;
    clear: both;
    padding: 2rem 0rem;
    margin-top: -15rem;
}

.blog_heading{
    margin: 1rem 0rem;
    text-align: center;
}

.blog_heading h1{
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 43px;
    letter-spacing: 0.075em;
    color: #000000;
}

.blog_heading p{
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 32px;
    /* identical to box height */
    letter-spacing: 0.075em;
    color: #787878;
}

.blog_description {
    display: block;
    width: 90%;
    word-wrap: break-word;
    margin: 1rem 0rem;
}

.share_buttons{
    display: flex;
    justify-content: center;
    align-items: center;
    float: left;
    margin-right: 69%;
}

.share_buttons p:nth-child(1){
    margin: 0rem 1rem;
    font-style: normal;
    font-weight: 600;
    font-size: 26px;
    line-height: 35px;
    /* identical to box height */
    color: #FAB84C;
}
.share_buttons span{
    /* background: #000000; */
    
    margin: 0rem 1rem;
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    line-height: 35px;
    /* identical to box height */
    color: #000000;
}

.blogs_detail{
    height: 100%;
}
.bottom_cards h1{
    float: left;
}


.blog_cont{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}


.left_img{
    margin-right: 15rem;
}

.right_img{
    margin-left: 15rem;
}
.more_head h1{
    margin-top: 3rem;
    margin-bottom: 1rem;
    margin-left: 7rem;
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 37px;
    letter-spacing: 0.075em;
    
    color: #000000;
}


.blog_images{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 20%;
    height: 20%;
    /* margin: 1rem 0rem; */
  }
  
  .blog_images img{
    margin: 1rem 4rem;
    width:100%;
    height:100%;
  }

  .more_blogs{
      margin: 3rem 0rem;
  }
  .blog_image_middle{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 50%;
    height: 50%;
  }
  .blog_image_middle img{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 50%;
    height: 50%;
  }

  @media  (max-width: 500px) {
    .blog_heading h1{
        font-size: 25px;
        line-height: 30px;
        width: 100%;
    }
    .blog_images{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 50%;
        height: 50%;
    }
}
